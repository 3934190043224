/* eslint-disable import/extensions */
export default [
  {
    path: '/laporan-slip-gaji',
    name: 'laporan-slip-gaji',
    component: () => import('@/views/penggajian/slipgaji/list/SlipGajiList'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Master Potongan',
        },
        {
          text: 'Master Potongan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/laporan-slip-gaji/add',
    name: 'laporan-slip-gaji-add',
    component: () => import('@/views/penggajian/slipgaji/add/SlipGajiCreate'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Master Potongan',
        },
        {
          text: 'Master Potongan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/laporan-slip-gaji/edit/:id',
    name: 'laporan-slip-gaji-edit',
    component: () => import('@/views/penggajian/slipgaji/edit/SlipGajiEdit'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Master Potongan',
        },
        {
          text: 'Master Potongan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/laporan-slip-gaji/:id',
    name: 'laporan-slip-gaji-detail',
    component: () => import('@/views/penggajian/slipgaji/list/SlipGajiDetail'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Master Potongan',
        },
        {
          text: 'Master Potongan',
          active: true,
        },
      ],
    },
  },

]
