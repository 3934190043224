/* eslint-disable import/extensions */
export default [
  {
    path: '/rekap-izin-kelas',
    name: 'rekap-izin-kelas',
    component: () => import('@/views/absensi/izin/rekapizinkelas/list/RekapIzinKelasList'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Rekap Izin Kelas',
        },
        {
          text: 'Rekap Izin Kelas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/generate-jadwal/add',
    name: 'generate-jadwal-add',
    component: () => import('@/views/absensi/izin/rekapizinkelas/add/RekapIzinKelasCreate'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Rekap Izin Kelas',
        },
        {
          text: 'Rekap Izin Kelas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/generate-jadwal/edit/:id',
    name: 'generate-jadwal-edit',
    component: () => import('@/views/absensi/izin/rekapizinkelas/edit/RekapIzinKelasEdit'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Rekap Izin Kelas',
        },
        {
          text: 'Rekap Izin Kelas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/generate-jadwal/:id',
    name: 'generate-jadwal-detail',
    component: () => import('@/views/absensi/izin/rekapizinkelas/list/RekapIzinKelasDetail'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Rekap Izin Kelas',
        },
        {
          text: 'Rekap Izin Kelas',
          active: true,
        },
      ],
    },
  },
]
