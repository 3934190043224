/* eslint-disable import/extensions */
export default [
  {
    path: '/pembayaran-spp',
    name: 'pembayaran-spp',
    component: () => import('@/views/keuangan/pembayaranspp/list/PembayaranSPPList'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Pembayaran SPP',
        },
        {
          text: 'Pembayaran SPP',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pembayaran-spp/add',
    name: 'pembayaran-spp-add',
    component: () => import('@/views/keuangan/pembayaranspp/add/PembayaranSPPCreate'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Pembayaran SPP',
        },
        {
          text: 'Tambah Pembayaran SPP',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pembayaran-spp/edit/:id',
    name: 'pembayaran-spp-edit',
    component: () => import('@/views/keuangan/pembayaranspp/edit/PembayaranSPPEdit'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Pembayaran SPP',
        },
        {
          text: 'Update Pembayaran SPP',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pembayaran-spp/:id',
    name: 'pembayaran-spp-detail',
    component: () => import('@/views/keuangan/pembayaranspp/list/PembayaranSPPDetail'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Pembayaran SPP',
        },
        {
          text: 'Detail Pembayaran SPP',
          active: true,
        },
      ],
    },
  },

]
