/* eslint-disable import/extensions */
export default [
  {
    path: '/absensi-data-kehadiran',
    name: 'absensi-data-kehadiran',
    component: () => import('@/views/absensi/kehadiran/datakehadiran/list/DataKehadiranList'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Kehadiran',
        },
        {
          text: 'Data Kehadiran',
          active: true,
        },
      ],
    },
  },
  {
    path: '/absensi-data-kehadiran/edit/:id',
    name: 'absensi-data-kehadiran-edit',
    component: () => import('@/views/absensi/kehadiran/datakehadiran/edit/DataKehadiranEdit'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Kehadiran',
        },
        {
          text: 'Edit Data Kehadiran',
          active: true,
        },
      ],
    },
  },
  {
    path: '/absensi-belajar/add',
    name: 'absensi-belajar-add',
    component: () => import('@/views/absensi/absensibelajar/add/AbsensiBelajarCreate'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Agenda Mengajar',
        },
        {
          text: 'Tambah Absensi Belajar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/absensi-belajar/edit/:id',
    name: 'absensi-belajar-edit',
    component: () => import('@/views/absensi/absensibelajar/edit/AbsensiBelajarEdit'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Agenda Mengajar',
        },
        {
          text: 'Update Absensi Belajar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/absensi-belajar/:id',
    name: 'absensi-belajar-detail',
    component: () => import('@/views/absensi/absensibelajar/list/AbsensiBelajarDetail'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Agenda Mengajar',
        },
        {
          text: 'Detail Absensi Belajar',
          active: true,
        },
      ],
    },
  },
]
