/* eslint-disable import/extensions */
export default [
  {
    path: '/data-kartu',
    name: 'data-kartu',
    component: () => import('@/views/students/list/StudentList'),
    meta: {
      pageTitle: 'Kekartuan',
      breadcrumb: [
        {
          text: 'kartu',
        },
        {
          text: 'Data kartu',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kartu/add',
    name: 'data-kartu-add',
    component: () => import('@/views/students/add/StudentCreate'),
    meta: {
      pageTitle: 'Kekartuan',
      breadcrumb: [
        {
          text: 'kartu',
        },
        {
          text: 'Tambah Data kartu',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kartu/edit/:id',
    name: 'data-kartu-edit',
    component: () => import('@/views/students/edit/StudentEdit'),
    meta: {
      pageTitle: 'Kekartuan',
      breadcrumb: [
        {
          text: 'kartu',
        },
        {
          text: 'Update Data kartu',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kartu/:id',
    name: 'data-kartu-detail',
    component: () => import('@/views/students/list/StudentDetail'),
    meta: {
      pageTitle: 'Kekartuan',
      breadcrumb: [
        {
          text: 'kartu',
        },
        {
          text: 'Detail Data kartu',
          active: true,
        },
      ],
    },
  },

]
