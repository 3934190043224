/* eslint-disable import/extensions */
export default [
  {
    path: '/data-kategorisurat',
    name: 'data-kategorisurat',
    component: () => import('@/views/surat/kategorisurat/list/KategoriSuratList'),
    meta: {
      pageTitle: 'Management Surat',
      breadcrumb: [
        {
          text: 'Kategori Surat',
        },
        {
          text: 'Data Kategori Surat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kategorisurat/add',
    name: 'data-kategorisurat-add',
    component: () => import('@/views/surat/kategorisurat/add/KategoriSuratCreate'),
    meta: {
      pageTitle: 'Management Surat',
      breadcrumb: [
        {
          text: 'Kategori Surat',
        },
        {
          text: 'Tambah Kategori Surat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kategorisurat/edit/:id',
    name: 'data-kategorisurat-edit',
    component: () => import('@/views/surat/kategorisurat/edit/KategoriSuratEdit'),
    meta: {
      pageTitle: 'Management Surat',
      breadcrumb: [
        {
          text: 'Kategori Surat',
        },
        {
          text: 'Update Kategori Surat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kategorisurat/:id',
    name: 'data-kategorisurat-detail',
    component: () => import('@/views/surat/kategorisurat/list/KategoriSuratDetail'),
    meta: {
      pageTitle: 'Management Surat',
      breadcrumb: [
        {
          text: 'Kategori Surat',
        },
        {
          text: 'Detail Kategori Surat',
          active: true,
        },
      ],
    },
  },

]
