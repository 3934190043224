/* eslint-disable import/extensions */
export default [
  {
    path: '/gaji-tidak-tetap',
    name: 'gaji-tidak-tetap',
    component: () => import('@/views/penggajian/gajitidaktetap/list/GajiTidakTetapList'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Gaji Tidak Tetap',
        },
        {
          text: 'Gaji Tidak Tetap',
          active: true,
        },
      ],
    },
  },
  {
    path: '/gaji-tidak-tetap/add',
    name: 'gaji-tidak-tetap-add',
    component: () => import('@/views/penggajian/gajitidaktetap/add/GajiTidakTetapCreate'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Gaji Tidak Tetap',
        },
        {
          text: 'Gaji Tidak Tetap',
          active: true,
        },
      ],
    },
  },
  {
    path: '/gaji-tidak-tetap/edit/:id',
    name: 'gaji-tidak-tetap-edit',
    component: () => import('@/views/penggajian/gajitidaktetap/edit/GajiTidakTetapEdit'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Gaji Tidak Tetap',
        },
        {
          text: 'Gaji Tidak Tetap',
          active: true,
        },
      ],
    },
  },
  {
    path: '/gaji-tidak-tetap/:id',
    name: 'gaji-tidak-tetap-detail',
    component: () => import('@/views/penggajian/gajitidaktetap/list/GajiTidakTetapDetail'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Gaji Tidak Tetap',
        },
        {
          text: 'Gaji Tidak Tetap',
          active: true,
        },
      ],
    },
  },

]
