/* eslint-disable import/extensions */
export default [
  {
    path: '/fisik-kesehatan',
    name: 'fisik-kesehatan',
    component: () => import('@/views/akademik/nilai/nilaifisikkesehatan/list/NilaiFisikKesehatanList'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Nilai',
        },
        {
          text: 'Data Nilai Fisik & Kesehatan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fisik-kesehatan/add',
    name: 'fisik-kesehatan-add',
    component: () => import('@/views/akademik/nilai/nilaifisikkesehatan/add/NilaiFisikKesehatanCreate'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Nilai',
        },
        {
          text: 'Tambah Nilai Fisik & Kesehatan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fisik-kesehatan/edit/:id',
    name: 'fisik-kesehatan-edit',
    component: () => import('@/views/akademik/nilai/nilaifisikkesehatan/edit/NilaiFisikKesehatanEdit'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Nilai',
        },
        {
          text: 'Update Nilai Fisik & Kesehatan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fisik-kesehatan/:id',
    name: 'fisik-kesehatan-detail',
    component: () => import('@/views/akademik/nilai/nilaifisikkesehatan/list/NilaiFisikKesehatanDetail'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Nilai',
        },
        {
          text: 'Detail Nilai Fisik & Kesehatan',
          active: true,
        },
      ],
    },
  },
]
