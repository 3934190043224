/* eslint-disable import/extensions */
export default [
  {
    path: '/perpus-buku',
    name: 'perpus-buku',
    component: () => import('@/views/perpustakaan/buku/list/BukuList'),
    meta: {
      pageTitle: 'Perpustakaan',
      breadcrumb: [
        {
          text: 'buku',
        },
        {
          text: 'Data buku',
          active: true,
        },
      ],
    },
  },
  {
    path: '/perpus-buku/add',
    name: 'perpus-buku-add',
    component: () => import('@/views/perpustakaan/buku/add/BukuCreate'),
    meta: {
      pageTitle: 'Kebukuan',
      breadcrumb: [
        {
          text: 'buku',
        },
        {
          text: 'Tambah Data buku',
          active: true,
        },
      ],
    },
  },
  {
    path: '/perpus-buku/edit/:id',
    name: 'perpus-buku-edit',
    component: () => import('@/views/perpustakaan/buku/edit/BukuEdit'),
    meta: {
      pageTitle: 'Kebukuan',
      breadcrumb: [
        {
          text: 'buku',
        },
        {
          text: 'Update Data buku',
          active: true,
        },
      ],
    },
  },
  {
    path: '/perpus-buku/:id',
    name: 'perpus-buku-detail',
    component: () => import('@/views/perpustakaan/buku/list/BukuDetail'),
    meta: {
      pageTitle: 'Kebukuan',
      breadcrumb: [
        {
          text: 'buku',
        },
        {
          text: 'Detail Data buku',
          active: true,
        },
      ],
    },
  },

]
