/* eslint-disable import/extensions */
export default [
  {
    path: '/absensi-upload-data',
    name: 'absensi-upload-data',
    component: () => import('@/views/absensi/kehadiran/uploaddata/ImportData'),
    meta: {
      pageTitle: 'Kehadiran',
      breadcrumb: [
        {
          text: 'Upload Data',
        },
        {
          text: 'Upload Data',
          active: true,
        },
      ],
    },
  },  
]
