/* eslint-disable import/extensions */
export default [
  {
    path: '/data-studi-kasus',
    name: 'data-studi-kasus',
    component: () => import('@/views/konseling/studikasus/list/StudiKasusList'),
    meta: {
      pageTitle: 'Studi Kasus',
      breadcrumb: [
        {
          text: 'Konseling',
        },
        {
          text: 'Data Studi Kasus',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-studi-kasus/add',
    name: 'data-studi-kasus-add',
    component: () => import('@/views/konseling/studikasus/add/StudiKasusCreate'),
    meta: {
      pageTitle: 'Studi Kasus',
      breadcrumb: [
        {
          text: 'Konseling',
        },
        {
          text: 'Tambah Data Studi Kasus',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-studi-kasus/edit/:id',
    name: 'data-studi-kasus-edit',
    component: () => import('@/views/konseling/studikasus/edit/StudiKasusEdit'),
    meta: {
      pageTitle: 'Studi Kasus',
      breadcrumb: [
        {
          text: 'Konseling',
        },
        {
          text: 'Update Data Studi Kasus',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-studi-kasus/:id',
    name: 'data-studi-kasus-detail',
    component: () => import('@/views/konseling/studikasus/list/StudiKasusDetail'),
    meta: {
      pageTitle: 'Studi Kasus',
      breadcrumb: [
        {
          text: 'Konseling',
        },
        {
          text: 'Detail Data Studi Kasus',
          active: true,
        },
      ],
    },
  },

]
