/* eslint-disable import/extensions */
export default [
  {
    path: '/pe-absen-ekskul',
    name: 'pe-absen-ekskul',
    component: () => import('@/views/absensi/presensiekskul/absenekskul/list/AbsenEkskulList'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Absen Ekskul',
        },
        {
          text: 'Absen Ekskul',
          active: true,
        },
      ],
    },
  },
  {
    path: '/absensi-belajar/add',
    name: 'absensi-belajar-add',
    component: () => import('@/views/absensi/presensiekskul/absenekskul/add/AbsenEkskulCreate'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Absen Ekskul',
        },
        {
          text: 'Absen Ekskul',
          active: true,
        },
      ],
    },
  },
  {
    path: '/absensi-belajar/edit/:id',
    name: 'absensi-belajar-edit',
    component: () => import('@/views/absensi/presensiekskul/absenekskul/edit/AbsenEkskulEdit'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Absen Ekskul',
        },
        {
          text: 'Absen Ekskul',
          active: true,
        },
      ],
    },
  },
  {
    path: '/absensi-belajar/:id',
    name: 'absensi-belajar-detail',
    component: () => import('@/views/absensi/presensiekskul/absenekskul/list/AbsenEkskulDetail'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Absen Ekskul',
        },
        {
          text: 'Absen Ekskul',
          active: true,
        },
      ],
    },
  },
]
