/* eslint-disable import/extensions */
export default [
  {
    path: '/data-kehadiran',
    name: 'data-kehadiran',
    component: () => import('@/views/kepegawaian/kehadiran/list/KehadiranList'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Kehadiran',
        },
        {
          text: 'Data Kehadiran',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kehadiran/add',
    name: 'data-kehadiran-add',
    component: () => import('@/views/kepegawaian/kehadiran/add/KehadiranCreate'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Kehadiran',
        },
        {
          text: 'Tambah Data Kehadiran',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kehadiran/edit/:id',
    name: 'data-kehadiran-edit',
    component: () => import('@/views/kepegawaian/kehadiran/edit/KehadiranEdit'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Kehadiran',
        },
        {
          text: 'Update Data Kehadiran',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kehadiran/:id',
    name: 'data-kehadiran-detail',
    component: () => import('@/views/kepegawaian/kehadiran/list/KehadiranDetail'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Kehadiran',
        },
        {
          text: 'Detail Data Kehadiran',
          active: true,
        },
      ],
    },
  },

]
