/* eslint-disable import/extensions */
export default [
  {
    path: '/peserta-ekstrakurikuler',
    name: 'peserta-ekstrakurikuler',
    component: () => import('@/views/kesiswaan/ekstrakurikuler/pesertaekskul/list/PesertaEkskulList'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Ekstrakurikuler',
        },
        {
          text: 'Peserta Ekstrakurikuler',
          active: true,
        },
      ],
    },
  },
  {
    path: '/peserta-ekstrakurikuler/add',
    name: 'peserta-ekstrakurikuler-add',
    component: () => import('@/views/kesiswaan/ekstrakurikuler/pesertaekskul/add/PesertaEkskulCreate'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Ekstrakurikuler',
        },
        {
          text: 'Tambah Peserta Ekstrakurikuler',
          active: true,
        },
      ],
    },
  },
  {
    path: '/peserta-ekstrakurikuler/edit/:id',
    name: 'peserta-ekstrakurikuler-edit',
    component: () => import('@/views/kesiswaan/ekstrakurikuler/pesertaekskul/edit/PesertaEkskulEdit'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Ekstrakurikuler',
        },
        {
          text: 'Update Peserta Ekstrakurikuler',
          active: true,
        },
      ],
    },
  },
  {
    path: '/peserta-ekstrakurikuler/:id',
    name: 'peserta-ekstrakurikuler-detail',
    component: () => import('@/views/kesiswaan/ekstrakurikuler/pesertaekskul/list/PesertaEkskulDetail'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Ekstrakurikuler',
        },
        {
          text: 'Detail Peserta Ekstrakurikuler',
          active: true,
        },
      ],
    },
  },

]
