/* eslint-disable import/extensions */
export default [
  {
    path: '/master-keuangan',
    name: 'master-keuangan',
    component: () => import('@/views/keuangan/masterkeuangan/list/MasterKeuanganList'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Master Keuangan',
        },
        {
          text: 'Master Keuangan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master-keuangan/add',
    name: 'master-keuangan-add',
    component: () => import('@/views/keuangan/masterkeuangan/add/MasterKeuanganCreate'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Master Keuangan',
        },
        {
          text: 'Master Keuangan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master-keuangan/edit/:id',
    name: 'master-keuangan-edit',
    component: () => import('@/views/keuangan/masterkeuangan/edit/MasterKeuanganEdit'),
    meta: {
      pageTitle: 'keuangan',
      breadcrumb: [
        {
          text: 'Master Keuangan',
        },
        {
          text: 'Master Keuangan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master-keuangan/:id',
    name: 'master-keuangan-detail',
    component: () => import('@/views/keuangan/masterkeuangan/list/MasterKeuanganDetail'),
    meta: {
      pageTitle: 'Keanggotaan',
      breadcrumb: [
        {
          text: '  anggota',
        },
        {
          text: 'Detail Data anggota',
          active: true,
        },
      ],
    },
  },

]
