/* eslint-disable import/extensions */
export default [
  {
    path: '/rekap-izin-belajar',
    name: 'rekap-izin-belajar',
    component: () => import('@/views/absensi/izin/rekapizinbelajar/list/RekapIzinBelajarList'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Rekap Izin Belajar',
        },
        {
          text: 'Rekap Izin Belajar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/generate-jadwal/add',
    name: 'generate-jadwal-add',
    component: () => import('@/views/absensi/izin/rekapizinbelajar/add/RekapIzinBelajarCreate'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Rekap Izin Belajar',
        },
        {
          text: 'Rekap Izin Belajar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/generate-jadwal/edit/:id',
    name: 'generate-jadwal-edit',
    component: () => import('@/views/absensi/izin/rekapizinbelajar/edit/RekapIzinBelajarEdit'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Rekap Izin Belajar',
        },
        {
          text: 'Rekap Izin Belajar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/generate-jadwal/:id',
    name: 'generate-jadwal-detail',
    component: () => import('@/views/absensi/izin/rekapizinbelajar/list/RekapIzinBelajarDetail'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Rekap Izin Belajar',
        },
        {
          text: 'Rekap Izin Belajar',
          active: true,
        },
      ],
    },
  },
]
