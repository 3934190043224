/* eslint-disable import/extensions */
export default [
  {
    path: '/asset-pengembalian',
    name: 'asset-pengembalian',
    component: () => import('@/views/inventaris/assetpengembalian/list/AssetPengembalianList'),
    meta: {
      pageTitle: 'Asset',
      breadcrumb: [
        {
          text: 'Pengembalian Asset',
        },
        {
          text: 'Data Pengembalian Asset',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-assetpengembalian/add',
    name: 'data-assetpengembalian-add',
    component: () => import('@/views/students/add/StudentCreate'),
    meta: {
      pageTitle: 'Keassetpengembalianan',
      breadcrumb: [
        {
          text: 'assetpengembalian',
        },
        {
          text: 'Tambah Data assetpengembalian',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-assetpengembalian/edit/:id',
    name: 'data-assetpengembalian-edit',
    component: () => import('@/views/students/edit/StudentEdit'),
    meta: {
      pageTitle: 'Keassetpengembalianan',
      breadcrumb: [
        {
          text: 'assetpengembalian',
        },
        {
          text: 'Update Data assetpengembalian',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-assetpengembalian/:id',
    name: 'data-assetpengembalian-detail',
    component: () => import('@/views/students/list/StudentDetail'),
    meta: {
      pageTitle: 'Keassetpengembalianan',
      breadcrumb: [
        {
          text: 'assetpengembalian',
        },
        {
          text: 'Detail Data assetpengembalian',
          active: true,
        },
      ],
    },
  },

]
