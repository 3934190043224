/* eslint-disable import/extensions */
export default [
  {
    path: '/laporan-pembayaran',
    name: 'laporan-pembayaran',
    component: () => import('@/views/keuangan/laporanpembayaran/list/LaporanPembayaranList'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Laporan Pembayaran',
        },
        {
          text: 'Laporan Pembayaran',
          active: true,
        },
      ],
    },
  }
]
