/* eslint-disable import/extensions */
export default [
  {
    path: '/nilai-ujian',
    name: 'nilai-ujian',
    component: () => import('@/views/akademik/nilai/nilaiujian/list/NilaiUjianList'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Nilai',
        },
        {
          text: 'Data Nilai Ujian',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nilai-ujian/add',
    name: 'nilai-ujian-add',
    component: () => import('@/views/akademik/nilai/nilaiujian/add/NilaiUjianCreate'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Nilai',
        },
        {
          text: 'Tambah Nilai Ujian',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nilai-ujian/edit/:id',
    name: 'nilai-ujian-edit',
    component: () => import('@/views/akademik/nilai/nilaiujian/edit/NilaiUjianEdit'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Nilai',
        },
        {
          text: 'Update Nilai Ujian',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nilai-ujian/:id',
    name: 'nilai-ujian-detail',
    component: () => import('@/views/akademik/nilai/nilaiujian/list/NilaiUjianDetail'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Nilai',
        },
        {
          text: 'Detail Nilai Ujian',
          active: true,
        },
      ],
    },
  },
]
