/* eslint-disable import/extensions */
export default [
  {
    path: '/jadwal',
    name: 'jadwal',
    component: () => import('@/views/akademik/agenda/jadwalmengajar/list/JadwalMengajarList'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Agenda Mengajar',
        },
        {
          text: 'Jadwal Mengajar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pembagian-jadwal/add',
    name: 'pembagian-jadwal-add',
    component: () => import('@/views/jadwal/pembagianjadwal/add/PembagianJadwalCreate'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Data Kelas',
        },
        {
          text: 'Tambah Data Kelas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pembagian-jadwal/edit/:id',
    name: 'pembagian-jadwal-edit',
    component: () => import('@/views/jadwal/pembagianjadwal/edit/PembagianJadwalEdit'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Data Kelas',
        },
        {
          text: 'Update Data Kelas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pembagian-jadwal/:id',
    name: 'pembagian-jadwal-detail',
    component: () => import('@/views/jadwal/pembagianjadwal/list/PembagianJadwalDetail'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Data Kelas',
        },
        {
          text: 'Detail Data Kelas',
          active: true,
        },
      ],
    },
  },
]
