/* eslint-disable import/extensions */
export default [
  {
    path: '/pe-rekap-ekskul',
    name: 'pe-rekap-ekskul',
    component: () => import('@/views/absensi/presensiekskul/rekapekskul/list/RekapEkskulList'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Rekap Ekskul',
        },
        {
          text: 'Rekap Ekskul',
          active: true,
        },
      ],
    },
  },
  {
    path: '/generate-jadwal/add',
    name: 'generate-jadwal-add',
    component: () => import('@/views/absensi/presensiekskul/rekapekskul/add/RekapEkskulCreate'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Rekap Ekskul',
        },
        {
          text: 'Rekap Ekskul',
          active: true,
        },
      ],
    },
  },
  {
    path: '/generate-jadwal/edit/:id',
    name: 'generate-jadwal-edit',
    component: () => import('@/views/absensi/presensiekskul/rekapekskul/edit/RekapEkskulEdit'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Rekap Ekskul',
        },
        {
          text: 'Rekap Ekskul',
          active: true,
        },
      ],
    },
  },
  {
    path: '/generate-jadwal/:id',
    name: 'generate-jadwal-detail',
    component: () => import('@/views/absensi/presensiekskul/rekapekskul/list/RekapEkskulDetail'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Rekap Ekskul',
        },
        {
          text: 'Rekap Ekskul',
          active: true,
        },
      ],
    },
  },
]
