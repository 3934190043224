/* eslint-disable import/extensions */
export default [
  {
    path: '/data-kd2sosial',
    name: 'data-kd2sosial',
    component: () => import('@/views/akademik/kompetensi/kdsosial/list/KdSosialList'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Kompetensi',
        },
        {
          text: 'Kompetensi Dasar Sosial',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kd2sosial/add',
    name: 'data-kd2sosial-add',
    component: () => import('@/views/akademik/kompetensi/kdsosial/add/KdSosialCreate'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Kompetensi',
        },
        {
          text: 'Tambah Kompetensi Dasar ',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kd2sosial/edit/:id',
    name: 'data-kd2sosial-edit',
    component: () => import('@/views/akademik/kompetensi/kdsosial/edit/KdSosialEdit'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Kompetensi',
        },
        {
          text: 'Update Data Kelas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kd2sosial/:id',
    name: 'data-kd2sosial-detail',
    component: () => import('@/views/akademik/kompetensi/kdsosial/list/KdSosialDetail'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Data Kelas',
        },
        {
          text: 'Detail Data Kelas',
          active: true,
        },
      ],
    },
  },
]
