/* eslint-disable import/extensions */
export default [
  {
    path: '/data-pengembalian',
    name: 'data-pengembalian',
    component: () => import('@/views/perpustakaan/pengembalian/list/PengembalianList'),
    meta: {
      pageTitle: 'Kepengembalianan',
      breadcrumb: [
        {
          text: 'pengembalian',
        },
        {
          text: 'Data pengembalian',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-pengembalian/add',
    name: 'data-pengembalian-add',
    component: () => import('@/views/perpustakaan/pengembalian/add/PengembalianCreate'),
    meta: {
      pageTitle: 'Kepengembalianan',
      breadcrumb: [
        {
          text: 'pengembalian',
        },
        {
          text: 'Tambah Data pengembalian',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-pengembalian/edit/:id',
    name: 'data-pengembalian-edit',
    component: () => import('@/views/perpustakaan/pengembalian/edit/PengembalianEdit'),
    meta: {
      pageTitle: 'Kepengembalianan',
      breadcrumb: [
        {
          text: 'pengembalian',
        },
        {
          text: 'Update Data pengembalian',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-pengembalian/:id',
    name: 'data-pengembalian-detail',
    component: () => import('@/views/perpustakaan/pengembalian/list/PengembalianDetail'),
    meta: {
      pageTitle: 'Kepengembalianan',
      breadcrumb: [
        {
          text: 'pengembalian',
        },
        {
          text: 'Detail Data pengembalian',
          active: true,
        },
      ],
    },
  },

]
