/* eslint-disable import/extensions */
export default [
  {
    path: '/laporan-bank-transfer',
    name: 'laporan-bank-transfer',
    component: () => import('@/views/penggajian/laporanbanktransfer/list/LaporanBankTransferList'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Laporan Bank Transfer',
        },
        {
          text: 'Laporan Bank Transfer',
          active: true,
        },
      ],
    },
  },
  {
    path: '/laporan-bank-transfer/add',
    name: 'laporan-bank-transfer-add',
    component: () => import('@/views/penggajian/laporanbanktransfer/add/LaporanBankTransferCreate'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Laporan Bank Transfer',
        },
        {
          text: 'Laporan Bank Transfer',
          active: true,
        },
      ],
    },
  },
  {
    path: '/laporan-bank-transfer/edit/:id',
    name: 'laporan-bank-transfer-edit',
    component: () => import('@/views/penggajian/laporanbanktransfer/edit/LaporanBankTransferEdit'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Laporan Bank Transfer',
        },
        {
          text: 'Laporan Bank Transfer',
          active: true,
        },
      ],
    },
  },
  {
    path: '/laporan-bank-transfer/:id',
    name: 'laporan-bank-transfer-detail',
    component: () => import('@/views/penggajian/laporanbanktransfer/list/LaporanBankTransferDetail'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Laporan Bank Transfer',
        },
        {
          text: 'Laporan Bank Transfer',
          active: true,
        },
      ],
    },
  },

]
