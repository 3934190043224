/* eslint-disable import/extensions */
export default [
  {
    path: '/setting-pembayaran',
    name: 'setting-pembayaran',
    component: () =>
      import ('@/views/keuangan/paymentsetting/list/PaymentSettingList'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Master keuangan',
        },
        {
          text: 'Pengaturan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/setting-pembayaran/add',
    name: 'setting-pembayaran-add',
    component: () =>
      import ('@/views/keuangan/paymentsetting/add/PaymentSettingCreate'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Master keuangan',
        },
        {
          text: 'Pengaturan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/setting-pembayaran/edit/:id',
    name: 'setting-pembayaran-edit',
    component: () =>
      import ('@/views/keuangan/paymentsetting/edit/PaymentSettingEdit'),
    meta: {
      pageTitle: 'keuangan',
      breadcrumb: [
        {
          text: 'Master keuangan',
        },
        {
          text: 'Pengaturan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/setting-pembayaran/:id',
    name: 'setting-pembayaran-detail',
    component: () =>
      import ('@/views/keuangan/paymentsetting/list/PaymentSettingDetail'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Master keuangan',
        },
        {
          text: 'Pengaturan',
          active: true,
        },
      ],
    },
  },
];
