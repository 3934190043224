/* eslint-disable import/extensions */
export default [
  {
    path: '/tugas-sekolah',
    name: 'tugas-sekolah',
    component: () => import('@/views/jadwal/tugassekolah/list/TugasSekolahList'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Jadwal KBM',
        },
        {
          text: 'Tugas Sekolah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tugas-sekolah/add',
    name: 'tugas-sekolah-add',
    component: () => import('@/views/jadwal/tugassekolah/add/TugasSekolahCreate'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Data Kenaikan Kelas',
        },
        {
          text: 'Tambah Data Kenaikan Kelas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tugas-sekolah/edit/:id',
    name: 'tugas-sekolah-edit',
    component: () => import('@/views/jadwal/tugassekolah/edit/TugasSekolahEdit'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Data Kenaikan Kelas',
        },
        {
          text: 'Update Data Kenaikan Kelas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tugas-sekolah/:id',
    name: 'tugas-sekolah-detail',
    component: () => import('@/views/jadwal/tugassekolah/list/TugasSekolahDetail'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Data Kenaikan Kelas',
        },
        {
          text: 'Detail Data Kenaikan Kelas',
          active: true,
        },
      ],
    },
  },
]
