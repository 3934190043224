/* eslint-disable import/extensions */
export default [
  {
    path: '/kegiatan-sekolah',
    name: 'kegiatan-sekolah',
    component: () => import('@/views/kepegawaian/kegiatansekolah/list/KegiatanSekolahList'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Kegiatan Sekolah',
        },
        {
          text: 'Data Kegiatan Sekolah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kegiatan-sekolah/add',
    name: 'data-kegiatan-sekolah-add',
    component: () => import('@/views/kepegawaian/kegiatansekolah/add/KegiatanSekolahCreate'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Kegiatan Sekolah',
        },
        {
          text: 'Tambah Data Kegiatan Sekolah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kegiatan-sekolah/edit/:id',
    name: 'data-kegiatan-sekolah-edit',
    component: () => import('@/views/kepegawaian/kegiatansekolah/edit/KegiatanSekolahEdit'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Kegiatan Sekolah',
        },
        {
          text: 'Update Data Kegiatan Sekolah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kegiatan-sekolah/:id',
    name: 'data-kegiatan-sekolah-detail',
    component: () => import('@/views/kepegawaian/kegiatansekolah/list/KegiatanSekolahDetail'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Kegiatan Sekolah',
        },
        {
          text: 'Detail Kegiatan Sekolah',
          active: true,
        },
      ],
    },
  },

]
