/* eslint-disable import/extensions */
export default [
  {
    path: '/perangkat-mengajar',
    name: 'perangkat-mengajar',
    component: () => import('@/views/akademik/perangkatmengajar/masterdata/list/PerangkatMengajarList'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Perangkat Mengajar',
        },
        {
          text: 'Data Perangkat Mengajar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/perangkat-mengajar/add',
    name: 'perangkat-mengajar-add',
    component: () => import('@/views/akademik/perangkatmengajar/masterdata/add/PerangkatMengajarCreate'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Perangkat Mengajar',
        },
        {
          text: 'Tambah Perangkat Mengajar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/perangkat-mengajar/edit/:id',
    name: 'perangkat-mengajar-edit',
    component: () => import('@/views/akademik/perangkatmengajar/masterdata/edit/PerangkatMengajarEdit'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Perangkat Mengajar',
        },
        {
          text: 'Update Perangkat Mengajar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/perangkat-mengajar/:id',
    name: 'perangkat-mengajar-detail',
    component: () => import('@/views/akademik/perangkatmengajar/masterdata/list/PerangkatMengajarDetail'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Perangkat Mengajar',
        },
        {
          text: 'Detail Perangkat Mengajar',
          active: true,
        },
      ],
    },
  },
]
