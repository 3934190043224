/* eslint-disable import/extensions */
export default [
  {
    path: '/data-mastersurat',
    name: 'data-mastersurat',
    component: () => import('@/views/surat/mastersurat/list/MasterSuratList'),
    meta: {
      pageTitle: 'Management Surat',
      breadcrumb: [
        {
          text: 'Master Surat',
        },
        {
          text: 'Data Master Surat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-mastersurat/add',
    name: 'data-mastersurat-add',
    component: () => import('@/views/surat/mastersurat/add/MasterSuratCreate'),
    meta: {
      pageTitle: 'Management Surat',
      breadcrumb: [
        {
          text: 'Master Surat',
        },
        {
          text: 'Tambah Master Surat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-mastersurat/edit/:id',
    name: 'data-mastersurat-edit',
    component: () => import('@/views/surat/mastersurat/edit/MasterSuratEdit'),
    meta: {
      pageTitle: 'Management Surat',
      breadcrumb: [
        {
          text: 'Master Surat',
        },
        {
          text: 'Update Master Surat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-mastersurat/:id',
    name: 'data-mastersurat-detail',
    component: () => import('@/views/surat/mastersurat/list/MasterSuratDetail'),
    meta: {
      pageTitle: 'Management Surat',
      breadcrumb: [
        {
          text: 'Master Surat',
        },
        {
          text: 'Detail Master Surat',
          active: true,
        },
      ],
    },
  },

]
