/* eslint-disable import/extensions */
export default [
  {
    path: '/pb-rekap-mapel',
    name: 'pb-rekap-mapel',
    component: () => import('@/views/absensi/presensibelajar/rekapmapel/list/RekapMapelList'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Rekap Mapel',
        },
        {
          text: 'Rekap Mapel',
          active: true,
        },
      ],
    },
  },
  {
    path: '/generate-jadwal/add',
    name: 'generate-jadwal-add',
    component: () => import('@/views/absensi/presensibelajar/rekapmapel/add/RekapMapelCreate'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Rekap Mapel',
        },
        {
          text: 'Rekap Mapel',
          active: true,
        },
      ],
    },
  },
  {
    path: '/generate-jadwal/edit/:id',
    name: 'generate-jadwal-edit',
    component: () => import('@/views/absensi/presensibelajar/rekapmapel/edit/RekapMapelEdit'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Rekap Mapel',
        },
        {
          text: 'Rekap Mapel',
          active: true,
        },
      ],
    },
  },
  {
    path: '/generate-jadwal/:id',
    name: 'generate-jadwal-detail',
    component: () => import('@/views/absensi/presensibelajar/rekapmapel/list/RekapMapelDetail'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Rekap Mapel',
        },
        {
          text: 'Rekap Mapel',
          active: true,
        },
      ],
    },
  },
]
