/* eslint-disable import/extensions */
export default [
  {
    path: '/data-kd4keterampilan',
    name: 'data-kd4keterampilan',
    component: () => import('@/views/akademik/kompetensi/kdketerampilan/list/KdKeterampilanList'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Kompetensi',
        },
        {
          text: 'Kompetensi Dasar Keterampilan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kd4keterampilan/add',
    name: 'data-kd4keterampilan-add',
    component: () => import('@/views/akademik/kompetensi/kdketerampilan//add/KdKeterampilanCreate'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Kompetensi',
        },
        {
          text: 'Tambah Kompetensi Dasar Keterampilan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kd4keterampilan/edit/:id',
    name: 'data-kd4keterampilan-edit',
    component: () => import('@/views/akademik/kompetensi/kdketerampilan//edit/KdKeterampilanEdit'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Kompetensi',
        },
        {
          text: 'Update Kompetensi Dasar Keterampilan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kd4keterampilan/:id',
    name: 'data-kd4keterampilan-detail',
    component: () => import('@/views/akademik/kompetensi/kdketerampilan/list/KdKeterampilanDetail'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Kompetensi',
        },
        {
          text: 'Detail Kompetensi Dasar Keterampilan',
          active: true,
        },
      ],
    },
  },
]
