/* eslint-disable import/extensions */
export default [
  {
    path: '/rpp-guru',
    name: 'rpp-guru',
    component: () => import('@/views/akademik/perangkatmengajar/rppguru/list/RppGuruList'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Perangkat Mengajar',
        },
        {
          text: 'RPP Guru',
          active: true,
        },
      ],
    },
  },
  {
    path: '/rpp-guru/add',
    name: 'rpp-guru-add',
    component: () => import('@/views/akademik/perangkatmengajar/rppguru/add/RppGuruCreate'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Perangkat Mengajar',
        },
        {
          text: 'Tambah RPP Guru',
          active: true,
        },
      ],
    },
  },
  {
    path: '/rpp-guru/edit/:id',
    name: 'rpp-guru-edit',
    component: () => import('@/views/akademik/perangkatmengajar/rppguru/edit/RppGuruEdit'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Perangkat Mengajar',
        },
        {
          text: 'Update RPP Guru',
          active: true,
        },
      ],
    },
  },
  {
    path: '/rpp-guru/:id',
    name: 'rpp-guru-detail',
    component: () => import('@/views/akademik/perangkatmengajar/rppguru/list/RppGuruDetail'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Perangkat Mengajar',
        },
        {
          text: 'Detail RPP Guru',
          active: true,
        },
      ],
    },
  },
]
