/* eslint-disable import/extensions */
export default [
  {
    path: '/data-penugasan',
    name: 'data-penugasan',
    component: () => import('@/views/kepegawaian/penugasan/list/PenugasanList'),
    meta: {
      pageTitle: 'Penugasan',
      breadcrumb: [
        {
          text: 'Data Penugasan',
        },
        {
          text: 'Data Penugasan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-penugasan/add',
    name: 'data-penugasan-add',
    component: () => import('@/views/kepegawaian/penugasan/add/PenugasanCreate'),
    meta: {
      pageTitle: 'Penugasan',
      breadcrumb: [
        {
          text: 'Data Penugasan',
        },
        {
          text: 'Tambah Data Penugasan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-penugasan/edit/:id',
    name: 'data-penugasan-edit',
    component: () => import('@/views/kepegawaian/penugasan/edit/PenugasanEdit'),
    meta: {
      pageTitle: 'Penugasan',
      breadcrumb: [
        {
          text: 'Data Penugasan',
        },
        {
          text: 'Update Data Penugasan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-penugasan/:id',
    name: 'data-penugasan-detail',
    component: () => import('@/views/kepegawaian/penugasan/list/PenugasanDetail'),
    meta: {
      pageTitle: 'Penugasan',
      breadcrumb: [
        {
          text: 'Data Penugasan',
        },
        {
          text: 'Detail Data Penugasan',
          active: true,
        },
      ],
    },
  },

]
