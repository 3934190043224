/* eslint-disable import/extensions */
export default [
  {
    path: '/detail-mapel',
    name: 'detail-mapel',
    component: () => import('@/views/akademik/matapelajaran/detailmapel/list/DetailMapelList'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Mata Pelajaran',
        },
        {
          text: 'Detail Mata Pelajaran',
          active: true,
        },
      ],
    },
  },
  {
    path: '/detail-mapel-add/add',
    name: 'detail-mapel-add',
    component: () => import('@/views/akademik/matapelajaran/detailmapel/add/DetailMapelCreate'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Mata Pelajaran',
        },
        {
          text: 'Tambah Mata Pelajaran',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mata-pelajaran/edit/:id',
    name: 'mata-pelajaran-edit',
    component: () => import('@/views/akademik/matapelajaran/detailmapel/edit/DetailMapelEdit'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Mata Pelajaran',
        },
        {
          text: 'Update Mata Pelajaran',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mata-pelajaran/:id',
    name: 'mata-pelajaran-detail',
    component: () => import('@/views/akademik/matapelajaran/detailmapel/list/DetailMapelDetail'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Mata Pelajaran',
        },
        {
          text: 'Detail Mata Pelajaran',
          active: true,
        },
      ],
    },
  },
]
