/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
export default [
  {
    path: '/instruktur-ekstrakurikuler',
    name: 'instruktur-ekstrakurikuler',
    component: () => import('@/views/kesiswaan/ekstrakurikuler/instrukturekskul/list/InstrukturEkskulList'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Ekstrakurikuler',
        },
        {
          text: 'Instruktur Ekstrakurikuler',
          active: true,
        },
      ],
    },
  },
  {
    path: '/instruktur-ekstrakurikuler/add',
    name: 'instruktur-ekstrakurikuler-add',
    component: () => import('@/views/kesiswaan/ekstrakurikuler/instrukturekskul/add/InstrukturEkskulCreate'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Ekstrakurikuler',
        },
        {
          text: 'Tambah Instruktur Ekstrakurikuler',
          active: true,
        },
      ],
    },
  },
  {
    path: '/instruktur-ekstrakurikuler/edit/:id',
    name: 'instruktur-ekstrakurikuler-edit',
    component: () => import('@/views/kesiswaan/ekstrakurikuler/instrukturekskul/edit/InstrukturEkskulEdit'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Ekstrakurikuler',
        },
        {
          text: 'Update Instruktur Ekstrakurikuler',
          active: true,
        },
      ],
    },
  },
  {
    path: '/instruktur-ekstrakurikuler/:id',
    name: 'instruktur-ekstrakurikuler-detail',
    component: () => import('@/views/kesiswaan/ekstrakurikuler/instrukturekskul/list/InstrukturEkskulDetail'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Ekstrakurikuler',
        },
        {
          text: 'Detail Instruktur Ekstrakurikuler',
          active: true,
        },
      ],
    },
  },

]
