/* eslint-disable import/extensions */
export default [
  {
    path: '/gaji-tetap',
    name: 'gaji-tetap',
    component: () => import('@/views/penggajian/gajitetap/list/GajiTetapList'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Gaji Tetap',
        },
        {
          text: 'Gaji Tetap',
          active: true,
        },
      ],
    },
  },
  {
    path: '/gaji-tetap/add',
    name: 'gaji-tetap-add',
    component: () => import('@/views/penggajian/gajitetap/add/GajiTetapCreate'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Gaji Tetap',
        },
        {
          text: 'Gaji Tetap',
          active: true,
        },
      ],
    },
  },
  {
    path: '/gaji-tetap/edit/:id',
    name: 'gaji-tetap-edit',
    component: () => import('@/views/penggajian/gajitetap/edit/GajiTetapEdit'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Gaji Tetap',
        },
        {
          text: 'Gaji Tetap',
          active: true,
        },
      ],
    },
  },
  {
    path: '/gaji-tetap/:id',
    name: 'gaji-tetap-detail',
    component: () => import('@/views/penggajian/gajitetap/list/GajiTetapDetail'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Gaji Tetap',
        },
        {
          text: 'Gaji Tetap',
          active: true,
        },
      ],
    },
  },

]
