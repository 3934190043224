/* eslint-disable import/extensions */
export default [
  {
    path: '/data-barangmasuk',
    name: 'data-barangmasuk',
    component: () => import('@/views/inventaris/inventarisbarangmasuk/list/InventarisBarangMasukList'),
    meta: {
      pageTitle: 'Barang Masuuk',
      breadcrumb: [
        {
          text: 'Barang Masuuk',
        },
        {
          text: 'Data Barang Masuuk',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-barangmasuk/add',
    name: 'data-barangmasuk-add',
    component: () => import('@/views/students/add/StudentCreate'),
    meta: {
      pageTitle: 'Kebarangmasukan',
      breadcrumb: [
        {
          text: 'barangmasuk',
        },
        {
          text: 'Tambah Data barangmasuk',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-barangmasuk/edit/:id',
    name: 'data-barangmasuk-edit',
    component: () => import('@/views/students/edit/StudentEdit'),
    meta: {
      pageTitle: 'Kebarangmasukan',
      breadcrumb: [
        {
          text: 'barangmasuk',
        },
        {
          text: 'Update Data barangmasuk',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-barangmasuk/:id',
    name: 'data-barangmasuk-detail',
    component: () => import('@/views/students/list/StudentDetail'),
    meta: {
      pageTitle: 'Kebarangmasukan',
      breadcrumb: [
        {
          text: 'barangmasuk',
        },
        {
          text: 'Detail Data barangmasuk',
          active: true,
        },
      ],
    },
  },

]
