/* eslint-disable import/extensions */
export default [
  {
    path: '/asset-peminjaman',
    name: 'asset-peminjaman',
    component: () => import('@/views/inventaris/assetpeminjaman/list/AssetPeminjamanList'),
    meta: {
      pageTitle: 'Asset',
      breadcrumb: [
        {
          text: 'Peminjaman Asset',
        },
        {
          text: 'Data Peminjaman Asset',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset-peminjaman/add',
    name: 'asset-peminjaman-add',
    component: () => import('@/views/inventaris/assetpeminjaman/add/AssetPeminjamanCreate'),
    meta: {
      pageTitle: 'Asset',
      breadcrumb: [
        {
          text: 'Peminjaman Asset',
        },
        {
          text: 'Tambah Data Peminjaman Asset',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-assetpeminjaman/edit/:id',
    name: 'data-assetpeminjaman-edit',
    component: () => import('@/views/students/edit/StudentEdit'),
    meta: {
      pageTitle: 'Keassetpeminjamanan',
      breadcrumb: [
        {
          text: 'assetpeminjaman',
        },
        {
          text: 'Update Data assetpeminjaman',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-assetpeminjaman/:id',
    name: 'data-assetpeminjaman-detail',
    component: () => import('@/views/students/list/StudentDetail'),
    meta: {
      pageTitle: 'Keassetpeminjamanan',
      breadcrumb: [
        {
          text: 'assetpeminjaman',
        },
        {
          text: 'Detail Data assetpeminjaman',
          active: true,
        },
      ],
    },
  },

]
