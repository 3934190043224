/* eslint-disable import/extensions */
export default [
  {
    path: '/data-ekstrakurikuler',
    name: 'data-ekstrakurikuler',
    component: () => import('@/views/kesiswaan/ekstrakurikuler/masterekskul/list/EkskulList'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Ekstrakurikuler',
        },
        {
          text: 'Data Ekstrakurikuler',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-ekstrakurikuler/add',
    name: 'data-ekstrakurikuler-add',
    component: () => import('@/views/kesiswaan/ekstrakurikuler/masterekskul/add/EkskulCreate'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Ekstrakurikuler',
        },
        {
          text: 'Tambah Data Ekstrakurikuler',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-ekstrakurikuler/edit/:id',
    name: 'data-ekstrakurikuler-edit',
    component: () => import('@/views/kesiswaan/ekstrakurikuler/masterekskul/edit/EkskulEdit'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Ekstrakurikuler',
        },
        {
          text: 'Update Data Ekstrakurikuler',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-ekstrakurikuler/:id',
    name: 'data-ekstrakurikuler-detail',
    component: () => import('@/views/kesiswaan/ekstrakurikuler/masterekskul/list/EkskulDetail'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Ekstrakurikuler',
        },
        {
          text: 'Detail Data Ekstrakurikuler',
          active: true,
        },
      ],
    },
  },

]
