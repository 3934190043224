/* eslint-disable import/extensions */
export default [
  {
    path: '/data-referensi',
    name: 'data-referensi',
    component: () =>
      import ('@/views/penggajian/datareferensi/list/DataReferensiList'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Data Referensi',
        },
        {
          text: 'Data Referensi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-referensi/add',
    name: 'data-referensi-add',
    component: () =>
      import ('@/views/penggajian/datareferensi/add/DataReferensiCreate'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Data Referensi',
        },
        {
          text: 'Data Referensi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-referensi/edit/:id',
    name: 'data-referensi-edit',
    component: () =>
      import ('@/views/penggajian/datareferensi/edit/DataReferensiEdit'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Data Referensi',
        },
        {
          text: 'Data Referensi',
          active: true,
        },
      ],
    },
  },
];
