/* eslint-disable import/extensions */
export default [
  {
    path: '/data-set-umum-kondisi',
    name: 'data-set-umum-kondisi',
    component: () => import('@/views/students/list/StudentList'),
    meta: {
      pageTitle: 'Keset-umum-kondisian',
      breadcrumb: [
        {
          text: 'set-umum-kondisi',
        },
        {
          text: 'Data set-umum-kondisi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-set-umum-kondisi/add',
    name: 'data-set-umum-kondisi-add',
    component: () => import('@/views/students/add/StudentCreate'),
    meta: {
      pageTitle: 'Keset-umum-kondisian',
      breadcrumb: [
        {
          text: 'set-umum-kondisi',
        },
        {
          text: 'Tambah Data set-umum-kondisi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-set-umum-kondisi/edit/:id',
    name: 'data-set-umum-kondisi-edit',
    component: () => import('@/views/students/edit/StudentEdit'),
    meta: {
      pageTitle: 'Keset-umum-kondisian',
      breadcrumb: [
        {
          text: 'set-umum-kondisi',
        },
        {
          text: 'Update Data set-umum-kondisi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-set-umum-kondisi/:id',
    name: 'data-set-umum-kondisi-detail',
    component: () => import('@/views/students/list/StudentDetail'),
    meta: {
      pageTitle: 'Keset-umum-kondisian',
      breadcrumb: [
        {
          text: 'set-umum-kondisi',
        },
        {
          text: 'Detail Data set-umum-kondisi',
          active: true,
        },
      ],
    },
  },

]
