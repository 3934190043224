/* eslint-disable import/extensions */
export default [
  {
    path: '/saran',
    name: 'saran',
    component: () => import('@/views/jadwal/generatejadwal/list/GenerateJadwalList'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Data Kenaikan Kelas',
        },
        {
          text: 'Data Kenaikan Kelas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/generate-jadwal/add',
    name: 'generate-jadwal-add',
    component: () => import('@/views/jadwal/generatejadwal/add/GenerateJadwalCreate'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Data Kenaikan Kelas',
        },
        {
          text: 'Tambah Data Kenaikan Kelas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/generate-jadwal/edit/:id',
    name: 'generate-jadwal-edit',
    component: () => import('@/views/jadwal/generatejadwal/edit/GenerateJadwalEdit'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Data Kenaikan Kelas',
        },
        {
          text: 'Update Data Kenaikan Kelas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/generate-jadwal/:id',
    name: 'generate-jadwal-detail',
    component: () => import('@/views/jadwal/generatejadwal/list/GenerateJadwalDetail'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Data Kenaikan Kelas',
        },
        {
          text: 'Detail Data Kenaikan Kelas',
          active: true,
        },
      ],
    },
  },
]
