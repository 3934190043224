/* eslint-disable import/extensions */
export default [
  {
    path: '/dashoard-informasi',
    name: 'dashoard-informasi',
    component: () => import('@/views/informasi/manajemeninformasi/list/ManajemenInformasiList'),
    meta: {
      pageTitle: 'Dashboard Informasi',
      breadcrumb: [
        {
          text: 'Dashboard Informasi',
        },
        {
          text: 'Dashboard Informasi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dashoard-informasi/add',
    name: 'dashoard-informasi-add',
    component: () => import('@/views/students/add/StudentCreate'),
    meta: {
      pageTitle: 'Kedashboard-informasian',
      breadcrumb: [
        {
          text: 'dashboard-informasi',
        },
        {
          text: 'Tambah Data dashboard-informasi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dashoard-informasi/edit/:id',
    name: 'dashoard-informasi-edit',
    component: () => import('@/views/students/edit/StudentEdit'),
    meta: {
      pageTitle: 'Kedashboard-informasian',
      breadcrumb: [
        {
          text: 'dashboard-informasi',
        },
        {
          text: 'Update Data dashboard-informasi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dashoard-informasi/:id',
    name: 'dashoard-informasi-detail',
    component: () => import('@/views/students/list/StudentDetail'),
    meta: {
      pageTitle: 'Kedashboard-informasian',
      breadcrumb: [
        {
          text: 'dashboard-informasi',
        },
        {
          text: 'Detail Data dashboard-informasi',
          active: true,
        },
      ],
    },
  },

]
