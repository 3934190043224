/* eslint-disable import/extensions */
export default [
  {
    path: '/data-jadwal-tes',
    name: 'data-jadwal-tes',
    component: () => import('@/views/ppdb/datajadwaltest/list/JadwalTestList'),
    meta: {
      pageTitle: 'Data Jadwal Test',
      breadcrumb: [
        {
          text: 'PPDB',          
        },
        {
          text: 'Data Jadwal Test',
          active: true,
        },
      ],
    },
  },
  {
    path: '/menu-data/add',
    name: 'menu-data-add',
    component: () => import('@/views/menu/datamenu/add/MenuCreate'),
    meta: {
      pageTitle: 'Menu Management',
      breadcrumb: [
        {
          text: 'Menu Management',
        },
        {
          text: 'Tambah Data Menu',
          active: true,
        },
      ],
    },
  },
  {
    path: '/menu-data/edit/:id',
    name: 'menu-data-edit',
    component: () => import('@/views/menu/datamenu/edit/MenuEdit'),
    meta: {
      pageTitle: 'Menu Management',
      breadcrumb: [
        {
          text: 'Menu Management',
        },
        {
          text: 'Update Data Menu',
          active: true,
        },
      ],
    },
  },
  {
    path: '/menu-data/:id',
    name: 'menu-data-detail',
    component: () => import('@/views/menu/datamenu/list/MenuDetail'),
    meta: {
      pageTitle: 'Menu Management',
      breadcrumb: [
        {
          text: 'Menu Management',
        },
        {
          text: 'Detail Data Menu',
          active: true,
        },
      ],
    },
  },
]
