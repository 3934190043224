/* eslint-disable import/extensions */
export default [
  {
    path: '/data-inventaris',
    name: 'data-inventaris',
    component: () => import('@/views/inventaris/masterdatainventaris/list/InventarisList'),
    meta: {
      pageTitle: 'Keinventarisan',
      breadcrumb: [
        {
          text: 'inventaris',
        },
        {
          text: 'Data inventaris',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-inventaris/add',
    name: 'data-inventaris-add',
    component: () => import('@/views/students/add/StudentCreate'),
    meta: {
      pageTitle: 'Keinventarisan',
      breadcrumb: [
        {
          text: 'inventaris',
        },
        {
          text: 'Tambah Data inventaris',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-inventaris/edit/:id',
    name: 'data-inventaris-edit',
    component: () => import('@/views/students/edit/StudentEdit'),
    meta: {
      pageTitle: 'Keinventarisan',
      breadcrumb: [
        {
          text: 'inventaris',
        },
        {
          text: 'Update Data inventaris',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-inventaris/:id',
    name: 'data-inventaris-detail',
    component: () => import('@/views/students/list/StudentDetail'),
    meta: {
      pageTitle: 'Keinventarisan',
      breadcrumb: [
        {
          text: 'inventaris',
        },
        {
          text: 'Detail Data inventaris',
          active: true,
        },
      ],
    },
  },

]
