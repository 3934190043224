/* eslint-disable import/extensions */
export default [
  {
    path: '/bpjs',
    name: 'bpjs',
    component: () => import('@/views/penggajian/bpjs/list/BpjsList'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'BPJS',
        },
        {
          text: 'BPJS',
          active: true,
        },
      ],
    },
  },
  {
    path: '/bpjs/add',
    name: 'bpjs-add',
    component: () => import('@/views/penggajian/bpjs/add/BpjsCreate'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'BPJS',
        },
        {
          text: 'BPJS',
          active: true,
        },
      ],
    },
  },
  {
    path: '/bpjs/edit/:id',
    name: 'bpjs-edit',
    component: () => import('@/views/penggajian/bpjs/edit/BpjsEdit'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'BPJS',
        },
        {
          text: 'BPJS',
          active: true,
        },
      ],
    },
  },
  {
    path: '/bpjs/:id',
    name: 'bpjs-detail',
    component: () => import('@/views/penggajian/bpjs/list/BpjsDetail'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'BPJS',
        },
        {
          text: 'BPJS',
          active: true,
        },
      ],
    },
  },

]
