/* eslint-disable import/extensions */
export default [
  {
    path: '/tipe-jam-belajar',
    name: 'tipe-jam-belajar',
    component: () => import('@/views/akademik/matapelajaran/tipejambelajar/list/TipeJamBelajarList'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Mata Pelajaran',
        },
        {
          text: 'Data Tipe Hari Belajar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tipe-jam-belajar/add',
    name: 'tipe-jam-belajar-add',
    component: () => import('@/views/akademik/matapelajaran/tipejambelajar/add/TipeJamBelajarCreate'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Mata Pelajaran',
        },
        {
          text: 'Tambah Tipe Jam Belajar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tipe-jam-belajar/edit/:id',
    name: 'tipe-jam-belajar-edit',
    component: () => import('@/views/akademik/matapelajaran/tipejambelajar/edit/TipeJamBelajarEdit'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Mata Pelajaran',
        },
        {
          text: 'Update Tipe Jam Belajar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tipe-jam-belajar/:id',
    name: 'tipe-jam-belajar-detail',
    component: () => import('@/views/akademik/matapelajaran/tipejambelajar/list/TipeJamBelajarDetail'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Mata Pelajaran',
        },
        {
          text: 'Detail Tipe Jam Belajar',
          active: true,
        },
      ],
    },
  },
]
