/* eslint-disable import/extensions */
export default [
  {
    path: '/program-guru',
    name: 'program-guru',
    component: () => import('@/views/akademik/perangkatmengajar/programguru/list/ProgramGuruList'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Perangkat Mengajar',
        },
        {
          text: 'Program Guru',
          active: true,
        },
      ],
    },
  },
  {
    path: '/program-guru/add',
    name: 'program-guru-add',
    component: () => import('@/views/akademik/perangkatmengajar/programguru/add/ProgramGuruCreate'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Perangkat Mengajar',
        },
        {
          text: 'Tambah Program Guru',
          active: true,
        },
      ],
    },
  },
  {
    path: '/program-guru/edit/:id',
    name: 'program-guru-edit',
    component: () => import('@/views/akademik/perangkatmengajar/programguru/edit/ProgramGuruEdit'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Perangkat Mengajar',
        },
        {
          text: 'Update Program Guru',
          active: true,
        },
      ],
    },
  },
  {
    path: '/program-guru/:id',
    name: 'program-guru-detail',
    component: () => import('@/views/akademik/perangkatmengajar/programguru/list/ProgramGuruDetail'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Perangkat Mengajar',
        },
        {
          text: 'Detail Program Guru',
          active: true,
        },
      ],
    },
  },
]
