/* eslint-disable import/extensions */
export default [
  {
    path: '/data-pegawai',
    name: 'data-pegawai',
    component: () => import('@/views/kepegawaian/datakepegawaian/pegawai/list/PegawaiList'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Data Kepegawaian',
        },
        {
          text: 'Data Pegawai',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-pegawai/add',
    name: 'data-pegawai-add',
    component: () => import('@/views/kepegawaian/datakepegawaian/pegawai/add/PegawaiCreate'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Data Kepegawaian',
        },
        {
          text: 'Tambah Data Pegawai',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-pegawai/edit/:id',
    name: 'data-pegawai-edit',
    component: () => import('@/views/kepegawaian/datakepegawaian/pegawai/edit/PegawaiEdit'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Data Kepegawaian',
        },
        {
          text: 'Update Data Pegawai',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-pegawai/:id',
    name: 'data-pegawai-detail',
    component: () => import('@/views/kepegawaian/datakepegawaian/pegawai/list/PegawaiDetail'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Data Kepegawaian',
        },
        {
          text: 'Detail Data Pegawai',
          active: true,
        },
      ],
    },
  },
]
