/* eslint-disable import/extensions */
export default [
  {
    path: '/data-orangtua',
    name: 'data-orangtua',
    component: () => import('@/views/parents/list/ParentList'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Buku Induk Siswa',
        },
        {
          text: 'Data Orangtua',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-orangtua/add',
    name: 'data-orangtua-add',
    component: () => import('@/views/parents/add/ParentCreate'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Buku Induk Siswa',
        },
        {
          text: 'Tambah Data Orangtua',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-orangtua/edit/:id',
    name: 'data-orangtua-edit',
    component: () => import('@/views/parents/edit/ParentEdit'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Buku Induk Siswa',
        },
        {
          text: 'Update Data Orangtua',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-orangtua/:id',
    name: 'data-orangtua-detail',
    component: () => import('@/views/parents/list/ParentDetail'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Buku Induk Siswa',
        },
        {
          text: 'Detail Data Orangtua',
          active: true,
        },
      ],
    },
  },

]
