/* eslint-disable import/extensions */
export default [
  {
    path: '/jadwal-mengajar',
    name: 'jadwal-mengajar',
    component: () => import('@/views/jadwal/jadwalmengajar/list/JadwalMengajarList'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Jadwal KBM',
        },
        {
          text: 'Jadwal Mengajar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/jadwal-mengajar/add',
    name: 'jadwal-mengajar-add',
    component: () => import('@/views/jadwal/jadwalmengajar/add/JadwalMengajarCreate'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Data Kenaikan Kelas',
        },
        {
          text: 'Tambah Data Kenaikan Kelas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/jadwal-mengajar/edit/:id',
    name: 'jadwal-mengajar-edit',
    component: () => import('@/views/jadwal/jadwalmengajar/edit/JadwalMengajarEdit'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Data Kenaikan Kelas',
        },
        {
          text: 'Update Data Kenaikan Kelas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/jadwal-mengajar/:id',
    name: 'jadwal-mengajar-detail',
    component: () => import('@/views/jadwal/jadwalmengajar/list/JadwalMengajarDetail'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Data Kenaikan Kelas',
        },
        {
          text: 'Detail Data Kenaikan Kelas',
          active: true,
        },
      ],
    },
  },
]
