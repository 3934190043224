/* eslint-disable import/extensions */
export default [
  {
    path: '/data-kd3pengetahuan',
    name: 'data-kd3pengetahuan',
    component: () => import('@/views/akademik/kompetensi/kdpengetahuan/list/KdPengetahuanList'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Kompetensi',
        },
        {
          text: 'Kompetensi Dasar Pengetahuan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kd3pengetahuan/add',
    name: 'data-kd3pengetahuan-add',
    component: () => import('@/views/akademik/kompetensi/kdpengetahuan/add/KdPengetahuanCreate'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Kompetensi',
        },
        {
          text: 'Tambah Kompetensi Dasar Pengetahuan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kd3pengetahuan/edit/:id',
    name: 'data-kd3pengetahuan-edit',
    component: () => import('@/views/akademik/kompetensi/kdpengetahuan/edit/KdPengetahuanEdit'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Kompetensi',
        },
        {
          text: 'Update Kompetensi Dasar Pengetahuan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kd3pengetahuan/:id',
    name: 'data-kd3pengetahuan-detail',
    component: () => import('@/views/akademik/kompetensi/kdpengetahuan/list/KdPengetahuanDetail'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Kompetensi',
        },
        {
          text: 'Detail Kompetensi Dasar Pengetahuan',
          active: true,
        },
      ],
    },
  },
]
