/* eslint-disable import/extensions */
export default [
  {
    path: '/nilai-harian',
    name: 'nilai-harian',
    component: () => import('@/views/akademik/nilai/nilaiharian/list/NilaiHarianList'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Nilai',
        },
        {
          text: 'Data Nilai Harian',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nilai-harian/add',
    name: 'nilai-harian-add',
    component: () => import('@/views/akademik/nilai/nilaiharian/add/NilaiHarianCreate'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Nilai',
        },
        {
          text: 'Tambah Data Nilai Harian',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nilai-harian/edit/:id',
    name: 'nilai-harian-edit',
    component: () => import('@/views/akademik/nilai/nilaiharian/edit/NilaiHarianEdit'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Nilai',
        },
        {
          text: 'Update Data Nilai Harian',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nilai-harian/:id',
    name: 'nilai-harian-detail',
    component: () => import('@/views/akademik/nilai/nilaiharian/list/NilaiHarianDetail'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Nilai',
        },
        {
          text: 'Detail Data Nilai Harian',
          active: true,
        },
      ],
    },
  },
]
