/* eslint-disable import/extensions */
export default [
  {
    path: '/data-manajemen-informasi',
    name: 'data-manajemen-informasi',
    component: () => import('@/views/students/list/StudentList'),
    meta: {
      pageTitle: 'Kemanajemen-informasian',
      breadcrumb: [
        {
          text: 'manajemen-informasi',
        },
        {
          text: 'Data manajemen-informasi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-manajemen-informasi/add',
    name: 'data-manajemen-informasi-add',
    component: () => import('@/views/students/add/StudentCreate'),
    meta: {
      pageTitle: 'Kemanajemen-informasian',
      breadcrumb: [
        {
          text: 'manajemen-informasi',
        },
        {
          text: 'Tambah Data manajemen-informasi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-manajemen-informasi/edit/:id',
    name: 'data-manajemen-informasi-edit',
    component: () => import('@/views/students/edit/StudentEdit'),
    meta: {
      pageTitle: 'Kemanajemen-informasian',
      breadcrumb: [
        {
          text: 'manajemen-informasi',
        },
        {
          text: 'Update Data manajemen-informasi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-manajemen-informasi/:id',
    name: 'data-manajemen-informasi-detail',
    component: () => import('@/views/students/list/StudentDetail'),
    meta: {
      pageTitle: 'Kemanajemen-informasian',
      breadcrumb: [
        {
          text: 'manajemen-informasi',
        },
        {
          text: 'Detail Data manajemen-informasi',
          active: true,
        },
      ],
    },
  },

]
