/* eslint-disable import/extensions */
export default [
  {
    path: '/data-suratkeluar',
    name: 'data-suratkeluar',
    component: () => import('@/views/surat/suratkeluar/list/SuratKeluarList'),
    meta: {
      pageTitle: 'Management Surat',
      breadcrumb: [
        {
          text: 'Surat Keluar',
        },
        {
          text: 'Data Surat Keluar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-suratkeluar/add',
    name: 'data-suratkeluar-add',
    component: () => import('@/views/surat/suratkeluar/add/SuratKeluarCreate'),
    meta: {
      pageTitle: 'Management Surat',
      breadcrumb: [
        {
          text: 'Surat Keluar',
        },
        {
          text: 'Tambah Data Surat Keluar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-suratkeluar/edit/:id',
    name: 'data-suratkeluar-edit',
    component: () => import('@/views/surat/suratkeluar/edit/SuratKeluarEdit'),
    meta: {
      pageTitle: 'Management Surat',
      breadcrumb: [
        {
          text: 'Surat Keluar',
        },
        {
          text: 'Update Data Surat Keluar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-suratkeluar/:id',
    name: 'data-suratkeluar-detail',
    component: () => import('@/views/surat/suratkeluar/list/SuratKeluarDetail'),
    meta: {
      pageTitle: 'Management Surat',
      breadcrumb: [
        {
          text: 'Surat Keluar',
        },
        {
          text: 'Detail Data Surat Keluar',
          active: true,
        },
      ],
    },
  },

]
