/* eslint-disable import/extensions */
export default [
  {
    path: '/data-kandidat-ketua',
    name: 'data-kandidat-ketua',
    component: () => import('@/views/osis/kandidatketua/list/KandidatKetuaList'),
    meta: {
      pageTitle: 'Kandidat Ketua',
      breadcrumb: [
        {
          text: 'OSIS',
        },
        {
          text: 'Data Kandidat Ketua',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kandidat-ketua/add',
    name: 'data-kandidat-ketua-add',
    component: () => import('@/views/osis/kandidatketua/add/KandidatKetuaCreate'),
    meta: {
      pageTitle: 'Kandidat Ketua',
      breadcrumb: [
        {
          text: 'OSIS',
        },
        {
          text: 'Tambah Data Kandidat Ketua',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kandidat-ketua/edit/:id',
    name: 'data-kandidat-ketua-edit',
    component: () => import('@/views/osis/kandidatketua/edit/KandidatKetuaEdit'),
    meta: {
      pageTitle: 'Kandidat Ketua',
      breadcrumb: [
        {
          text: 'OSIS',
        },
        {
          text: 'Update Data Kandidat Ketua',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kandidat-ketua/:id',
    name: 'data-kandidat-ketua-detail',
    component: () => import('@/views/osis/kandidatketua/list/KandidatKetuaDetail'),
    meta: {
      pageTitle: 'Kandidat Ketua',
      breadcrumb: [
        {
          text: 'OSIS',
        },
        {
          text: 'Detail Data Kandidat Ketua',
          active: true,
        },
      ],
    },
  },

]
