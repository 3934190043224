/* eslint-disable import/extensions */
export default [
  {
    path: '/laporan-rekap-gaji',
    name: 'laporan-rekap-gaji',
    component: () => import('@/views/penggajian/laporanrekapgaji/list/LaporanRekapGajiList'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Laporan Rekap Gaji',
        },
        {
          text: 'Laporan Rekap Gaji',
          active: true,
        },
      ],
    },
  },
  {
    path: '/laporan-rekap-gaji/add',
    name: 'laporan-rekap-gaji-add',
    component: () => import('@/views/penggajian/laporanrekapgaji/add/LaporanRekapGajiCreate'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Laporan Rekap Gaji',
        },
        {
          text: 'Laporan Rekap Gaji',
          active: true,
        },
      ],
    },
  },
  {
    path: '/laporan-rekap-gaji/filter/:id',
    name: 'laporan-rekap-gaji-filter',
    component: () => import('@/views/penggajian/laporanrekapgaji/list/LaporanRekapGajiList'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Laporan Rekap Gaji',
        },
        {
          text: 'Laporan Rekap Gaji',
          active: true,
        },
      ],
    },
  },
  {
    path: '/laporan-rekap-gaji/:id',
    name: 'laporan-rekap-gaji-detail',
    component: () => import('@/views/penggajian/laporanrekapgaji/list/LaporanRekapGajiDetail'),
    meta: {
      pageTitle: 'penggajian',
      breadcrumb: [
        {
          text: 'Laporan Rekap Gaji',
        },
        {
          text: 'Laporan Rekap Gaji',
          active: true,
        },
      ],
    },
  },

]
