/* eslint-disable import/extensions */
export default [
  {
    path: '/rekap-semester',
    name: 'rekap-semester',
    component: () => import('@/views/kepegawaian/rekapsemester/list/RekapSemesterList'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Rekap Semester',
        },
        {
          text: 'Data Rekap Semester',
          active: true,
        },
      ],
    },
  },
  {
    path: '/rekap-semester/add',
    name: 'rekap-semester-add',
    component: () => import('@/views/kepegawaian/rekapsemester/add/RekapSemesterCreate'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Rekap Semester',
        },
        {
          text: 'Tambah Data Rekap Semester',
          active: true,
        },
      ],
    },
  },
  {
    path: '/rekap-semester/edit/:id',
    name: 'rekap-semester-edit',
    component: () => import('@/views/kepegawaian/rekapsemester/edit/RekapSemesterEdit'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Rekap Semester',
        },
        {
          text: 'Update Data Rekap Semester',
          active: true,
        },
      ],
    },
  },
  {
    path: '/rekap-semester/:id',
    name: 'rekap-semester-detail',
    component: () => import('@/views/kepegawaian/rekapsemester/list/RekapSemesterDetail'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Rekap Semester',
        },
        {
          text: 'Detail Data Rekap Semester',
          active: true,
        },
      ],
    },
  },

]
