/* eslint-disable import/extensions */
export default [
  {
    path: '/import-foto',
    name: 'import-foto',
    component: () => import('@/views/kesiswaan/bukuinduk/importfoto/ImportFoto'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Buku Induk Siswa',
        },
        {
          text: 'Import Foto',
          active: true,
        },
      ],
    },
  },

]
