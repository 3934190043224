/* eslint-disable import/extensions */
export default [
  {
    path: '/perpus-anggota',
    name: 'perpus-anggota',
    component: () => import('@/views/perpustakaan/anggota/list/AnggotaList'),
    meta: {
      pageTitle: 'Keanggotaan',
      breadcrumb: [
        {
          text: 'anggota',
        },
        {
          text: 'Data anggota',
          active: true,
        },
      ],
    },
  },
  {
    path: '/perpus-anggota/add',
    name: 'perpus-anggota-add',
    component: () => import('@/views/perpustakaan/anggota/add/AnggotaCreate'),
    meta: {
      pageTitle: 'Keanggotaan',
      breadcrumb: [
        {
          text: '  anggota',
        },
        {
          text: 'Tambah Data anggota',
          active: true,
        },
      ],
    },
  },
  {
    path: '/perpus-anggota/edit/:id',
    name: 'perpus-anggota-edit',
    component: () => import('@/views/perpustakaan/anggota/edit/AnggotaEdit'),
    meta: {
      pageTitle: 'Keanggotaan',
      breadcrumb: [
        {
          text: '  anggota',
        },
        {
          text: 'Update Data anggota',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-anggota/:id',
    name: 'data-anggota-detail',
    component: () => import('@/views/perpustakaan/anggota/list/AnggotaDetail'),
    meta: {
      pageTitle: 'Keanggotaan',
      breadcrumb: [
        {
          text: '  anggota',
        },
        {
          text: 'Detail Data anggota',
          active: true,
        },
      ],
    },
  },

]
