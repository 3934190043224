/* eslint-disable import/extensions */
export default [
  {
    path: '/pembayaran-lainnya',
    name: 'pembayaran-lainnya',
    component: () => import('@/views/keuangan/pembayaranlainnya/list/PembayaranLainnyaList'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Pembayaran',
        },
        {
          text: 'Pembayaran Lainnya',
          active: true,
        },
      ],
    },
  },
  {
    path: '/perpus-anggota/add',
    name: 'perpus-anggota-add',
    component: () => import('@/views/keuangan/pembayaranlainnya/add/PembayaranLainnyaCreate'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Pembayaran',
        },
        {
          text: 'Pembayaran Lainnya',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pembayaran-lainnya/edit/:id',
    name: 'pembayaran-lainnya-edit',
    component: () => import('@/views/keuangan/pembayaranlainnya/edit/PembayaranLainnyaEdit'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Pembayaran',
        },
        {
          text: 'Pembayaran Lainnya',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-anggota/:id',
    name: 'data-anggota-detail',
    component: () => import('@/views/perpustakaan/anggota/list/AnggotaDetail'),
    meta: {
      pageTitle: 'Keanggotaan',
      breadcrumb: [
        {
          text: '  anggota',
        },
        {
          text: 'Detail Data anggota',
          active: true,
        },
      ],
    },
  },

]
