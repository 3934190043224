/* eslint-disable import/extensions */
export default [
  {
    path: '/data-pelanggaran',
    name: 'data-pelanggaran',
    component: () => import('@/views/konseling/pelanggaran/list/PelanggaranList'),
    meta: {
      pageTitle: 'Pelanggaran',
      breadcrumb: [
        {
          text: 'Konseling',
        },
        {
          text: 'Data Pelanggaran',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-pelanggaran/add',
    name: 'data-pelanggaran-add',
    component: () => import('@/views/konseling/pelanggaran/add/PelanggaranCreate'),
    meta: {
      pageTitle: 'Pelanggaran',
      breadcrumb: [
        {
          text: 'Konseling',
        },
        {
          text: 'Tambah Data Pelanggaran',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-pelanggaran/edit/:id',
    name: 'data-pelanggaran-edit',
    component: () => import('@/views/konseling/pelanggaran/edit/PelanggaranEdit'),
    meta: {
      pageTitle: 'Pelanggaran',
      breadcrumb: [
        {
          text: 'Konseling',
        },
        {
          text: 'Update Data Pelanggaran',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-pelanggaran/:id',
    name: 'data-pelanggaran-detail',
    component: () => import('@/views/konseling/pelanggaran/list/PelanggaranDetail'),
    meta: {
      pageTitle: 'Pelanggaran',
      breadcrumb: [
        {
          text: 'Konseling',
        },
        {
          text: 'Detail Data Pelanggaran',
          active: true,
        },
      ],
    },
  },

]
