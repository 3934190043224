/* eslint-disable import/extensions */
export default [
  {
    path: '/data-assetkeluar',
    name: 'data-assetkeluar',
    component: () => import('@/views/inventaris/assetkeluar/list/AssetKeluarList'),
    meta: {
      pageTitle: 'Asset',
      breadcrumb: [
        {
          text: 'Asset Keluar',
        },
        {
          text: 'Data Asset Keluar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-assetkeluar/add',
    name: 'data-assetkeluar-add',
    component: () => import('@/views/students/add/StudentCreate'),
    meta: {
      pageTitle: 'Keassetkeluaran',
      breadcrumb: [
        {
          text: 'assetkeluar',
        },
        {
          text: 'Tambah Data assetkeluar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-assetkeluar/edit/:id',
    name: 'data-assetkeluar-edit',
    component: () => import('@/views/students/edit/StudentEdit'),
    meta: {
      pageTitle: 'Keassetkeluaran',
      breadcrumb: [
        {
          text: 'assetkeluar',
        },
        {
          text: 'Update Data assetkeluar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-assetkeluar/:id',
    name: 'data-assetkeluar-detail',
    component: () => import('@/views/students/list/StudentDetail'),
    meta: {
      pageTitle: 'Keassetkeluaran',
      breadcrumb: [
        {
          text: 'assetkeluar',
        },
        {
          text: 'Detail Data assetkeluar',
          active: true,
        },
      ],
    },
  },

]
