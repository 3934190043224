export default [
  {
    path: '/data-calon-siswa',
    name: 'data-calon-siswa',
    component: () => import('@/views/ppdb/datacalonsiswa/list/CalonSiswaList'),
    meta: {
      pageTitle: 'Data Calon Siswa',
      breadcrumb: [
        {
          text: 'PPDB',
        },
        {
          text: 'Data Calon Siswa',
          active: true,
        },
      ],
    },
  },
  {
    path: '/menu-data/add',
    name: 'menu-data-add',
    component: () => import('@/views/menu/datamenu/add/MenuCreate'),
    meta: {
      pageTitle: 'Menu Management',
      breadcrumb: [
        {
          text: 'Menu Management',
        },
        {
          text: 'Tambah Data Menu',
          active: true,
        },
      ],
    },
  },
  {
    path: '/menu-data/edit/:id',
    name: 'menu-data-edit',
    component: () => import('@/views/menu/datamenu/edit/MenuEdit'),
    meta: {
      pageTitle: 'Menu Management',
      breadcrumb: [
        {
          text: 'Menu Management',
        },
        {
          text: 'Update Data Menu',
          active: true,
        },
      ],
    },
  },
  {
    path: '/menu-data/:id',
    name: 'menu-data-detail',
    component: () => import('@/views/menu/datamenu/list/MenuDetail'),
    meta: {
      pageTitle: 'Menu Management',
      breadcrumb: [
        {
          text: 'Menu Management',
        },
        {
          text: 'Detail Data Menu',
          active: true,
        },
      ],
    },
  },
]
