/* eslint-disable import/extensions */
export default [
  {
    path: '/pengajuan-absensi',
    name: 'pengajuan-absensi',
    component: () => import('@/views/kepegawaian/pengajuanabsensi/list/PengajuanAbsensiList'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Pengajuan Absensi',
        },
        {
          text: 'Data Pengajuan Absensi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pengajuan-absensi/add',
    name: 'pengajuan-absensi-add',
    component: () => import('@/views/kepegawaian/pengajuanabsensi/add/PengajuanAbsensiCreate'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Pengajuan Absensi',
        },
        {
          text: 'Tambah Pengajuan Absensi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pengajuan-absensi/edit/:id',
    name: 'pengajuan-absensi-edit',
    component: () => import('@/views/kepegawaian/pengajuanabsensi/edit/PengajuanAbsensiEdit'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Pengajuan Absensi',
        },
        {
          text: 'Update Pengajuan Absensi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pengajuan-absensi/:id',
    name: 'pengajuan-absensi-detail',
    component: () => import('@/views/kepegawaian/pengajuanabsensi/list/PengajuanAbsensiDetail'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Pengajuan Absensi',
        },
        {
          text: 'Detail Pengajuan Absensi',
          active: true,
        },
      ],
    },
  },

]
