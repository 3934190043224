/* eslint-disable import/extensions */
export default [
  {
    path: '/transaksi-upload-data',
    name: 'transaksi-upload-data',
    component: () => import('@/views/penggajian/transaksiuploaddata/list/UploadDataList'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Upload Data',
        },
        {
          text: 'Upload Data',
          active: true,
        },
      ],
    },
  },
  {
    path: '/transaksi-upload-data/add',
    name: 'transaksi-upload-data-add',
    component: () => import('@/views/penggajian/transaksiuploaddata/add/UploadDataCreate'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Upload Data',
        },
        {
          text: 'Upload Data',
          active: true,
        },
      ],
    },
  },
  {
    path: '/transaksi-upload-data/edit/:id',
    name: 'transaksi-upload-data-edit',
    component: () => import('@/views/penggajian/transaksiuploaddata/edit/UploadDataEdit'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Upload Data',
        },
        {
          text: 'Upload Data',
          active: true,
        },
      ],
    },
  },
  {
    path: '/transaksi-upload-data/:id',
    name: 'transaksi-upload-data-detail',
    component: () => import('@/views/penggajian/transaksiuploaddata/list/UploadDataDetail'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Upload Data',
        },
        {
          text: 'Upload Data',
          active: true,
        },
      ],
    },
  },

]
