/* eslint-disable import/extensions */
export default [
  {
    path: '/rekap-pegawai',
    name: 'rekap-pegawai',
    component: () => import('@/views/kepegawaian/rekappegawai/list/RekapPegawaiList'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Rekap Pegawai',
        },
        {
          text: 'Data Rekap Pegawai',
          active: true,
        },
      ],
    },
  },
  {
    path: '/rekap-pegawai/add',
    name: 'rekap-pegawai-add',
    component: () => import('@/views/kepegawaian/rekappegawai/add/RekapPegawaiCreate'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Rekap Pegawai',
        },
        {
          text: 'Tambah Data Rekap Pegawai',
          active: true,
        },
      ],
    },
  },
  {
    path: '/rekap-pegawai/edit/:id',
    name: 'rekap-pegawai-edit',
    component: () => import('@/views/kepegawaian/rekappegawai/edit/RekapPegawaiEdit'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Rekap Pegawai',
        },
        {
          text: 'Update Data Rekap Pegawai',
          active: true,
        },
      ],
    },
  },
  {
    path: '/rekap-pegawai/:id',
    name: 'rekap-pegawai-detail',
    component: () => import('@/views/kepegawaian/rekappegawai/list/RekapPegawaiDetail'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Rekap Pegawai',
        },
        {
          text: 'Detail Data Rekap Pegawai',
          active: true,
        },
      ],
    },
  },

]
