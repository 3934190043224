/* eslint-disable import/extensions */
export default [
  {
    path: '/data-sertifikasi',
    name: 'data-sertifikasi',
    component: () => import('@/views/kepegawaian/sertifikasi/list/SertifikasiList'),
    meta: {
      pageTitle: 'Sertifikasi',
      breadcrumb: [
        {
          text: 'Data Sertifikasi',
        },
        {
          text: 'Data Sertifikasi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-sertifikasi/add',
    name: 'data-sertifikasi-add',
    component: () => import('@/views/kepegawaian/sertifikasi/add/SertifikasiCreate'),
    meta: {
      pageTitle: 'Sertifikasi',
      breadcrumb: [
        {
          text: 'Data Sertifikasi',
        },
        {
          text: 'Tambah Data Sertifikasi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-sertifikasi/edit/:id',
    name: 'data-sertifikasi-edit',
    component: () => import('@/views/kepegawaian/sertifikasi/edit/SertifikasiEdit'),
    meta: {
      pageTitle: 'Sertifikasi',
      breadcrumb: [
        {
          text: 'Data sertifikasi',
        },
        {
          text: 'Update Data sertifikasi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-sertifikasi/:id',
    name: 'data-sertifikasi-detail',
    component: () => import('@/views/kepegawaian/sertifikasi/list/SertifikasiDetail'),
    meta: {
      pageTitle: 'sertifikasi',
      breadcrumb: [
        {
          text: 'Data sertifikasi',
        },
        {
          text: 'Detail Data sertifikasi',
          active: true,
        },
      ],
    },
  },
]
