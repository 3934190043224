/* eslint-disable import/extensions */
export default [
  {
    path: '/permintaan-mengajar',
    name: 'permintaan-mengajar',
    component: () => import('@/views/akademik/matapelajaran/permintaanmengajar/list/PermintaanMengajarList'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Mata Pelajaran',
        },
        {
          text: 'Permintaan Mengajar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/permintaan-mengajar/add',
    name: 'permintaan-mengajar-add',
    component: () => import('@/views/akademik/matapelajaran/permintaanmengajar/add/PermintaanMengajarCreate'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Mata Pelajaran',
        },
        {
          text: 'Tambah Permintaan Mengajar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/permintaan-mengajar/edit/:id',
    name: 'permintaan-mengajar-edit',
    component: () => import('@/views/akademik/matapelajaran/permintaanmengajar/edit/PermintaanMengajarEdit'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Mata Pelajaran',
        },
        {
          text: 'Update Permintaan Mengajar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/permintaan-mengajar/:id',
    name: 'permintaan-mengajar-detail',
    component: () => import('@/views/akademik/matapelajaran/permintaanmengajar/list/PermintaanMengajarDetail'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Mata Pelajaran',
        },
        {
          text: 'Detail Permintaan Mengajar',
          active: true,
        },
      ],
    },
  },
]
