/* eslint-disable import/extensions */
export default [
  {
    path: '/jam-belajar',
    name: 'jam-belajar',
    component: () => import('@/views/akademik/matapelajaran/jambelajar/list/JamBelajarList'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Mata Pelajaran',
        },
        {
          text: 'Jam Belajar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/jam-belajar/add',
    name: 'jam-belajar-add',
    component: () => import('@/views/akademik/matapelajaran/jambelajar/add/JamBelajarCreate'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Mata Pelajaran',
        },
        {
          text: 'Tambah Tipe Jam Belajar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/jam-belajar/edit/:id',
    name: 'jam-belajar-edit',
    component: () => import('@/views/akademik/matapelajaran/jambelajar/edit/JamBelajarEdit'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Mata Pelajaran',
        },
        {
          text: 'Update Tipe Jam Belajar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/jam-belajar/:id',
    name: 'jam-belajar-detail',
    component: () => import('@/views/akademik/matapelajaran/jambelajar/list/JamBelajarDetail'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Mata Pelajaran',
        },
        {
          text: 'Detail Tipe Jam Belajar',
          active: true,
        },
      ],
    },
  },
]
