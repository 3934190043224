/* eslint-disable import/extensions */
export default [
  {
    path: '/data-siswa',
    name: 'data-siswa',
    component: () => import('@/views/students/list/StudentList'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Buku Induk Siswa',
        },
        {
          text: 'Data Siswa',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-siswa/add',
    name: 'data-siswa-add',
    component: () => import('@/views/students/add/StudentCreate'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Buku Induk Siswa',
        },
        {
          text: 'Tambah Data Siswa',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-siswa/edit/:id',
    name: 'data-siswa-edit',
    component: () => import('@/views/students/edit/StudentEdit'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Buku Induk Siswa',
        },
        {
          text: 'Update Data Siswa',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-siswa/:id',
    name: 'data-siswa-detail',
    component: () => import('@/views/students/list/StudentDetail'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Buku Induk Siswa',
        },
        {
          text: 'Detail Data Siswa',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-siswa/add-personal',
    name: 'data-siswa-add-personal',
    component: () => import('@/views/students/add/StudentAdd'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Buku Induk Siswa',
        },
        {
          text: 'Tambah Data Siswa',
          active: true,
        },
      ],
    },
  },

]
