/* eslint-disable import/extensions */
export default [
  {
    path: '/laporan-tabungan',
    name: 'laporan-tabungan',
    component: () => import('@/views/keuangan/laporantabungan/list/LaporanTabunganList'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Laporan Tabungan',
        },
        {
          text: 'Laporan Tabungan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/laporan-tabungan/add',
    name: 'laporan-tabungan-add',
    component: () => import('@/views/keuangan/laporantabungan/add/LaporanTabunganCreate'),
    meta: {
      pageTitle: 'Keanggotaan',
      breadcrumb: [
        {
          text: 'Laporan Tabungan',
        },
        {
          text: 'Laporan Tabungan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/laporan-tabungan/edit/:id',
    name: 'laporan-tabungan-edit',
    component: () => import('@/views/keuangan/laporantabungan/edit/LaporanTabunganEdit'),
    meta: {
      pageTitle: 'Keanggotaan',
      breadcrumb: [
        {
          text: 'Laporan Tabungan',
        },
        {
          text: 'Laporan Tabungan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/laporan-tabungan/:id',
    name: 'laporan-tabungan-detail',
    component: () => import('@/views/keuangan/laporantabungan/list/LaporanTabunganDetail'),
    meta: {
      pageTitle: 'Keanggotaan',
      breadcrumb: [
        {
          text: 'Laporan Tabungan',
        },
        {
          text: 'Laporan Tabungan',
          active: true,
        },
      ],
    },
  },

]
