/* eslint-disable import/extensions */
export default [
  {
    path: '/data-struktur-osis',
    name: 'data-struktur-osis',
    component: () => import('@/views/osis/strukturosis/list/StrukturOsisList'),
    meta: {
      pageTitle: 'OSIS',
      breadcrumb: [
        {
          text: 'Struktur OSIS',
        },
        {
          text: 'Data Struktur OSIS',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-struktur-osis/add',
    name: 'data-struktur-osis-add',
    component: () => import('@/views/osis/strukturosis/add/StrukturOsisCreate'),
    meta: {
      pageTitle: 'OSIS',
      breadcrumb: [
        {
          text: 'Struktur OSIS',
        },
        {
          text: 'Tambah Data Struktur OSIS',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-struktur-osis/edit/:id',
    name: 'data-struktur-osis-edit',
    component: () => import('@/views/osis/strukturosis/edit/StrukturOsisEdit'),
    meta: {
      pageTitle: 'OSIS',
      breadcrumb: [
        {
          text: 'Struktur OSIS',
        },
        {
          text: 'Update Struktur OSIS',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-struktur-osis/:id',
    name: 'data-struktur-osis-detail',
    component: () => import('@/views/osis/strukturosis/list/StrukturOsisDetail'),
    meta: {
      pageTitle: 'OSIS',
      breadcrumb: [
        {
          text: 'Struktur OSIS',
        },
        {
          text: 'Detail Struktur OSIS',
          active: true,
        },
      ],
    },
  },

]
