/* eslint-disable import/extensions */
export default [
  {
    path: '/data-assetmasuk',
    name: 'data-assetmasuk',
    component: () => import('@/views/inventaris/assetmasuk/list/AssetMasukList'),
    meta: {
      pageTitle: 'Asset Masuk',
      breadcrumb: [
        {
          text: 'Asset Masuk',
        },
        {
          text: 'Data Asset Masuk',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-assetmasuk/add',
    name: 'data-assetmasuk-add',
    component: () => import('@/views/students/add/StudentCreate'),
    meta: {
      pageTitle: 'Keassetmasukan',
      breadcrumb: [
        {
          text: 'assetmasuk',
        },
        {
          text: 'Tambah Data assetmasuk',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-assetmasuk/edit/:id',
    name: 'data-assetmasuk-edit',
    component: () => import('@/views/students/edit/StudentEdit'),
    meta: {
      pageTitle: 'Keassetmasukan',
      breadcrumb: [
        {
          text: 'assetmasuk',
        },
        {
          text: 'Update Data assetmasuk',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-assetmasuk/:id',
    name: 'data-assetmasuk-detail',
    component: () => import('@/views/students/list/StudentDetail'),
    meta: {
      pageTitle: 'Keassetmasukan',
      breadcrumb: [
        {
          text: 'assetmasuk',
        },
        {
          text: 'Detail Data assetmasuk',
          active: true,
        },
      ],
    },
  },

]
