/* eslint-disable import/extensions */
export default [
  {
    path: '/cetak-kalender-akademik',
    name: 'cetak-kalender-akademik',
    component: () => import('@/views/akademik/kelas/list/KelasList'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Data Kelas',
        },
        {
          text: 'Data Kelas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kelas/add',
    name: 'data-kelas-add',
    component: () => import('@/views/akademik/kelas/add/KelasCreate'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Data Kelas',
        },
        {
          text: 'Tambah Data Kelas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kelas/edit/:id',
    name: 'data-kelas-edit',
    component: () => import('@/views/akademik/kelas/edit/KelasEdit'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Data Kelas',
        },
        {
          text: 'Update Data Kelas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kelas/:id',
    name: 'data-kelas-detail',
    component: () => import('@/views/akademik/kelas/list/KelasDetail'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Data Kelas',
        },
        {
          text: 'Detail Data Kelas',
          active: true,
        },
      ],
    },
  },
]
