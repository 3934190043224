/* eslint-disable import/extensions */
export default [
  {
    path: '/data-mutasi',
    name: 'data-mutasi',
    component: () => import('@/views/mutasi/list/MutasiList'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Buku Induk Siswa',
        },
        {
          text: 'Data Mutasi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-mutasi/add',
    name: 'data-mutasi-add',
    component: () => import('@/views/mutasi/add/MutasiCreate'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Buku Induk Siswa',
        },
        {
          text: 'Tambah Data Mutasi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-mutasi/edit/:id',
    name: 'data-mutasi-edit',
    component: () => import('@/views/mutasi/edit/MutasiEdit'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Buku Induk Siswa',
        },
        {
          text: 'Update Data Mutasi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-mutasi/:id',
    name: 'data-mutasi-detail',
    component: () => import('@/views/mutasi/list/MutasiDetail'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Buku Induk Siswa',
        },
        {
          text: 'Detail Data Mutasi',
          active: true,
        },
      ],
    },
  },

]
