/* eslint-disable import/extensions */
export default [
  {
    path: '/pb-upload-data',
    name: 'pb-upload-data',
    component: () => import('@/views/absensi/presensibelajar/uploaddata/ImportData'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Presensi Belajar',
        },
        {
          text: 'Upload Data',
          active: true,
        },
      ],
    },
  },  
]
