/* eslint-disable import/extensions */
export default [
  {
    path: '/transaksi-data-referensi',
    name: 'transaksi-data-referensi',
    component: () => import('@/views/penggajian/transaksidatareferensi/list/TransaksiDataReferensiList'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Transaksi Data Referensi',
        },
        {
          text: 'Transaksi Data Referensi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/transaksi-data-referensi/add',
    name: 'transaksi-data-referensi-add',
    component: () => import('@/views/penggajian/transaksidatareferensi/add/TransaksiDataReferensiCreate'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Transaksi Data Referensi',
        },
        {
          text: 'Transaksi Data Referensi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/transaksi-data-referensi/edit/:id',
    name: 'transaksi-data-referensi-edit',
    component: () => import('@/views/penggajian/transaksidatareferensi/edit/TransaksiDataReferensiEdit'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Transaksi Data Referensi',
        },
        {
          text: 'Transaksi Data Referensi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/transaksi-data-referensi/:id',
    name: 'transaksi-data-referensi-detail',
    component: () => import('@/views/penggajian/transaksidatareferensi/list/TransaksiDataReferensiDetail'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Transaksi Data Referensi',
        },
        {
          text: 'Transaksi Data Referensi',
          active: true,
        },
      ],
    },
  },

]
