/* eslint-disable import/extensions */
export default [
  {
    path: '/payment-type',
    name: 'payment-type',
    component: () => import('@/views/keuangan/paymenttype/list/PaymentTypeList'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Jenis Pembayaran',
        },
        {
          text: 'Jenis Pembayaran',
          active: true,
        },
      ],
    },
  },
  {
    path: '/payment-type/add/:id',
    name: 'payment-type-add',
    component: () => import('@/views/keuangan/paymenttype/add/PaymentTypeCreate'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'payment-type',
        },
        {
          text: 'Tambah payment-type',
          active: true,
        },
      ],
    },
  },
  {
    path: '/payment-type/edit/:id',
    name: 'payment-type-edit',
    component: () => import('@/views/keuangan/paymenttype/edit/PaymentTypeEdit'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'payment-type',
        },
        {
          text: 'Update payment-type',
          active: true,
        },
      ],
    },
  },
  {
    path: '/payment-type/:id',
    name: 'payment-type-detail',
    component: () => import('@/views/keuangan/paymenttype/list/PaymentTypeDetail'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'payment-type',
        },
        {
          text: 'Detail payment-type',
          active: true,
        },
      ],
    },
  },

]
