/* eslint-disable import/extensions */
export default [
  {
    path: '/import-data-pegawai',
    name: 'import-data-pegawai',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/kepegawaian/importdata/ImportData'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Data Kepegawaian',
        },
        {
          text: 'Import Data',
          active: true,
        },
      ],
    },
  },

]
