/* eslint-disable import/extensions */
export default [
  {
    path: '/nilai-keterampilan',
    name: 'nilai-keterampilan',
    component: () => import('@/views/akademik/nilai/nilaiketerampilan/list/NilaiKeterampilanList'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Nilai',
        },
        {
          text: 'Data Nilai Keterampilan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nilai-keterampilan/add',
    name: 'nilai-keterampilan-add',
    component: () => import('@/views/akademik/nilai/nilaiketerampilan/add/NilaiKeterampilanCreate'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Nilai',
        },
        {
          text: 'Tambah Nilai Keterampilan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nilai-keterampilan/edit/:id',
    name: 'nilai-keterampilan-edit',
    component: () => import('@/views/akademik/nilai/nilaiketerampilan//edit/NilaiKeterampilanEdit'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Nilai',
        },
        {
          text: 'Update Nilai Keterampilan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nilai-keterampilan/:id',
    name: 'nilai-keterampilan-detail',
    component: () => import('@/views/akademik/nilai/nilaiketerampilan//list/NilaiKeterampilanDetail'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Nilai',
        },
        {
          text: 'Detail Nilai Keterampilan',
          active: true,
        },
      ],
    },
  },
]
