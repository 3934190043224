/* eslint-disable import/extensions */
export default [
  {
    path: '/absensi-ekstrakurikuler',
    name: 'absensi-ekstrakurikuler',
    component: () => import('@/views/kesiswaan/ekstrakurikuler/absensiekskul/list/AbsensiEkskulList'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Ekstrakurikuler',
        },
        {
          text: 'Absensi Ekstrakurikuler',
          active: true,
        },
      ],
    },
  },
  {
    path: '/absensi-ekstrakurikuler/add',
    name: 'absensi-ekstrakurikuler-add',
    component: () => import('@/views/kesiswaan/ekstrakurikuler/absensiekskul/add/AbsensiEkskulCreate'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Ekstrakurikuler',
        },
        {
          text: 'Tambah Absensi Ekstrakurikuler',
          active: true,
        },
      ],
    },
  },
  {
    path: '/absensi-ekstrakurikuler/edit/:id',
    name: 'absensi-ekstrakurikuler-edit',
    component: () => import('@/views/kesiswaan/ekstrakurikuler/absensiekskul/edit/AbsensiEkskulEdit'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Ekstrakurikuler',
        },
        {
          text: 'Update Absensi Ekstrakurikuler',
          active: true,
        },
      ],
    },
  },
  {
    path: '/absensi-ekstrakurikuler/:id',
    name: 'absensi-ekstrakurikuler-detail',
    component: () => import('@/views/kesiswaan/ekstrakurikuler/absensiekskul/list/AbsensiEkskulDetail'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Ekstrakurikuler',
        },
        {
          text: 'Detail Absensi Ekstrakurikuler',
          active: true,
        },
      ],
    },
  },

]
