/* eslint-disable import/extensions */
export default [
  {
    path: '/nilai-sikap',
    name: 'nilai-sikap',
    component: () => import('@/views/akademik/nilai/nilaisikap/list/NilaiSikapList'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Nilai',
        },
        {
          text: 'Data Nilai Sikap',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nilai-sikap/add',
    name: 'nilai-sikap-add',
    component: () => import('@/views/akademik/nilai/nilaisikap/add/NilaiSikapCreate'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Nilai',
        },
        {
          text: 'Tambah Nilai Sikap',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nilai-sikap/edit/:id',
    name: 'nilai-sikap-edit',
    component: () => import('@/views/akademik/nilai/nilaisikap/edit/NilaiSikapEdit'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Nilai',
        },
        {
          text: 'Update Nilai Sikap',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nilai-sikap/:id',
    name: 'nilai-sikap-detail',
    component: () => import('@/views/akademik/nilai/nilaisikap/list/NilaiSikapDetail'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Nilai',
        },
        {
          text: 'Detail Nilai Sikap',
          active: true,
        },
      ],
    },
  },
]
