/* eslint-disable import/extensions */
export default [
  {
    path: '/data-laporan',
    name: 'data-laporan',
    component: () => import('@/views/perpustakaan/laporan/list/LaporanList'),
    meta: {
      pageTitle: 'Laporan',
      breadcrumb: [
        {
          text: 'laporan',
        },
        {
          text: 'Data laporan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-laporan/add',
    name: 'data-laporan-add',
    component: () => import('@/views/perpustakaan/laporan/add/LaporanCreate'),
    meta: {
      pageTitle: 'Laporan',
      breadcrumb: [
        {
          text: 'laporan',
        },
        {
          text: 'Tambah Data laporan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-laporan/edit/:id',
    name: 'data-laporan-edit',
    component: () => import('@/views/perpustakaan/laporan/edit/LaporanEdit'),
    meta: {
      pageTitle: 'Laporan',
      breadcrumb: [
        {
          text: 'laporan',
        },
        {
          text: 'Update Data laporan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-laporan/:id',
    name: 'data-laporan-detail',
    component: () => import('@/views/perpustakaan/laporan/list/LaporanDetail'),
    meta: {
      pageTitle: 'Laporan',
      breadcrumb: [
        {
          text: 'laporan',
        },
        {
          text: 'Detail Data laporan',
          active: true,
        },
      ],
    },
  },

]
