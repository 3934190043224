/* eslint-disable import/extensions */
export default [
  {
    path: '/data-kenaikan-kelas',
    name: 'data-kenaikan-kelas',
    component: () => import('@/views/akademik/kenaikankelas/list/KenaikanKelasList'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Data Kenaikan Kelas',
        },
        {
          text: 'Data Kenaikan Kelas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kenaikan-kelas/add',
    name: 'data-kenaikan-kelas-add',
    component: () => import('@/views/akademik/kenaikankelas/add/KenaikanKelasCreate'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Data Kenaikan Kelas',
        },
        {
          text: 'Tambah Data Kenaikan Kelas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kenaikan-kelas/edit/:id',
    name: 'data-kenaikan-kelas-edit',
    component: () => import('@/views/akademik/kenaikankelas/edit/KenaikanKelasEdit'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Data Kenaikan Kelas',
        },
        {
          text: 'Update Data Kenaikan Kelas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kenaikan-kelas/:id',
    name: 'data-kenaikan-kelas-detail',
    component: () => import('@/views/akademik/kenaikankelas/list/KenaikanKelasDetail'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Data Kenaikan Kelas',
        },
        {
          text: 'Detail Data Kenaikan Kelas',
          active: true,
        },
      ],
    },
  },
]
