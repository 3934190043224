/* eslint-disable import/extensions */
export default [
  {
    path: '/pembayaran-ujian',
    name: 'pembayaran-ujian',
    component: () => import('@/views/keuangan/pembayaranujian/list/PembayaranUjianList'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Pembayaran Ujian',
        },
        {
          text: 'Pembayaran Ujian',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pembayaran-ujian/add',
    name: 'pembayaran-ujian-add',
    component: () => import('@/views/keuangan/pembayaranujian/add/PembayaranUjianCreate'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Pembayaran Ujian',
        },
        {
          text: 'Tambah Pembayaran Ujian',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pembayaran-ujian/edit/:id',
    name: 'pembayaran-ujian-edit',
    component: () => import('@/views/keuangan/pembayaranujian/edit/PembayaranUjianEdit'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Pembayaran Ujian',
        },
        {
          text: 'Update Pembayaran Ujian',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pembayaran-ujian/:id',
    name: 'pembayaran-ujian-detail',
    component: () => import('@/views/keuangan/pembayaranujian/list/PembayaranUjianDetail'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Pembayaran Ujian',
        },
        {
          text: 'Detail Pembayaran Ujian',
          active: true,
        },
      ],
    },
  },

]
