/* eslint-disable import/extensions */
export default [
  {
    path: '/data-peminjaman',
    name: 'data-peminjaman',
    component: () => import('@/views/perpustakaan/peminjaman/list/PeminjamanList'),
    meta: {
      pageTitle: 'Peminjaman',
      breadcrumb: [
        {
          text: 'Peminjaman',
        },
        {
          text: 'Data Peminjaman',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-peminjaman/add',
    name: 'data-peminjaman-add',
    component: () => import('@/views/perpustakaan/peminjaman/add/PeminjamanCreate'),
    meta: {
      pageTitle: 'Peminjaman',
      breadcrumb: [
        {
          text: 'peminjaman',
        },
        {
          text: 'Tambah Data peminjaman',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-peminjaman/edit/:id',
    name: 'data-peminjaman-edit',
    component: () => import('@/views/perpustakaan/peminjaman/edit/PeminjamanEdit'),
    meta: {
      pageTitle: 'Peminjaman',
      breadcrumb: [
        {
          text: 'peminjaman',
        },
        {
          text: 'Update Data peminjaman',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-peminjaman/:id',
    name: 'data-peminjaman-detail',
    component: () => import('@/views/perpustakaan/peminjaman/list/PeminjamanDetail'),
    meta: {
      pageTitle: 'Peminjaman',
      breadcrumb: [
        {
          text: 'peminjaman',
        },
        {
          text: 'Detail Data peminjaman',
          active: true,
        },
      ],
    },
  },

]
