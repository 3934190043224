/* eslint-disable import/extensions */
export default [
  {
    path: '/rekap-kehadiran',
    name: 'rekap-kehadiran',
    component: () => import('@/views/kepegawaian/rekapkehadiran/list/RekapKehadiranList'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Rekap Kehadiran',
        },
        {
          text: 'Rekap Kehadiran Pegawai',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kehadiran/add',
    name: 'data-kehadiran-add',
    component: () => import('@/views/kepegawaian/rekapkehadiran/add/RekapKehadiranCreate'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Rekap Kehadiran',
        },
        {
          text: 'Tambah Data Rekap Kehadiran',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kehadiran/edit/:id',
    name: 'data-kehadiran-edit',
    component: () => import('@/views/kepegawaian/rekapkehadiran/edit/RekapKehadiranEdit'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Rekap Kehadiran',
        },
        {
          text: 'Update Data Rekap Kehadiran',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kehadiran/:id',
    name: 'data-kehadiran-detail',
    component: () => import('@/views/kepegawaian/rekapkehadiran/list/RekapKehadiranDetail'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Rekap Kehadiran',
        },
        {
          text: 'Detail Data Rekap Kehadiran',
          active: true,
        },
      ],
    },
  },

]
