/* eslint-disable import/extensions */
export default [
  {
    path: '/master-potongan',
    name: 'master-potongan',
    component: () => import('@/views/keuangan/masterpotongan/list/MasterPotonganList'),
    meta: {
      pageTitle: 'keuangan',
      breadcrumb: [
        {
          text: 'Master Potongan',
        },
        {
          text: 'Master Potongan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master-potongan/add',
    name: 'master-potongan-add',
    component: () => import('@/views/keuangan/masterpotongan/add/MasterPotonganCreate'),
    meta: {
      pageTitle: 'keuangan',
      breadcrumb: [
        {
          text: 'Master Potongan',
        },
        {
          text: 'Master Potongan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master-potongan/edit/:id',
    name: 'master-potongan-edit',
    component: () => import('@/views/keuangan/masterpotongan/edit/MasterPotonganEdit'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Master Potongan',
        },
        {
          text: 'Master Potongan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master-potongan/:id',
    name: 'master-potongan-detail',
    component: () => import('@/views/keuangan/masterpotongan/list/MasterPotonganDetail'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Master Potongan',
        },
        {
          text: 'Master Potongan',
          active: true,
        },
      ],
    },
  },

]
