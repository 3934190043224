/* eslint-disable import/extensions */
export default [
  {
    path: '/data-kd1spiritual',
    name: 'data-kd1spiritual',
    component: () => import('@/views/akademik/kompetensi/kdspiritual/list/KdSpiritualList'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Kompetensi',
        },
        {
          text: 'Kompetensi Dasar Spiritual',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kd1spiritual/add',
    name: 'data-kd1spiritual-add',
    component: () => import('@/views/akademik/kompetensi/kdspiritual/add/KdSpiritualCreate'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Kompetensi',
        },
        {
          text: 'Tambah Kompetensi Dasar Spiritual',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kd1spiritual/edit/:id',
    name: 'data-kd1spiritual-edit',
    component: () => import('@/views/akademik/kompetensi/kdspiritual/edit/KdSpiritualEdit'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Kompetensi',
        },
        {
          text: 'Update Kompetensi Dasar Spiritual',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kd1spiritual/:id',
    name: 'data-kd1spiritual-detail',
    component: () => import('@/views/akademik/kompetensi/kdspiritual/list/KdSpiritualDetail'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Kompetensi',
        },
        {
          text: 'Detail Kompetensi Dasar Spiritual',
          active: true,
        },
      ],
    },
  },
]
