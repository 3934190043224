/* eslint-disable import/extensions */
export default [
  {
    path: '/pe-presentase-ekskul',
    name: 'pe-presentase-ekskul',
    component: () => import('@/views/absensi/presensiekskul/presentaseekskul/list/PresentaseEkskulList'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Presentase Ekskul',
        },
        {
          text: 'Presentase Ekskul',
          active: true,
        },
      ],
    },
  },
  {
    path: '/generate-jadwal/add',
    name: 'generate-jadwal-add',
    component: () => import('@/views/absensi/presensiekskul/presentaseekskul/add/PresentaseEkskulCreate'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Presentase Ekskul',
        },
        {
          text: 'Presentase Ekskul',
          active: true,
        },
      ],
    },
  },
  {
    path: '/generate-jadwal/edit/:id',
    name: 'generate-jadwal-edit',
    component: () => import('@/views/absensi/presensiekskul/presentaseekskul/edit/PresentaseEkskulEdit'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Presentase Ekskul',
        },
        {
          text: 'Presentase Ekskul',
          active: true,
        },
      ],
    },
  },
  {
    path: '/generate-jadwal/:id',
    name: 'generate-jadwal-detail',
    component: () => import('@/views/absensi/presensiekskul/presentaseekskul/list/PresentaseEkskulDetail'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Presentase Ekskul',
        },
        {
          text: 'Presentase Ekskul',
          active: true,
        },
      ],
    },
  },
]
