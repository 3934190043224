import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  congratulations: {
    name: 'A',
    saleToday: '15.000.000',
  },
  statisticsItems: [
    {
      icon: 'TrendingUpIcon',
      color: 'light-primary',
      title: '1200',
      subtitle: 'Siswa',
      customClass: 'mb-2 mb-xl-0',
    },
    {
      icon: 'UserIcon',
      color: 'light-info',
      title: '50',
      subtitle: 'Guru',
      customClass: 'mb-2 mb-xl-0',
    },
    {
      icon: 'BoxIcon',
      color: 'light-danger',
      title: '30',
      subtitle: 'Kelas',
      customClass: 'mb-2 mb-sm-0',
    },
    {
      icon: 'UserXIcon',
      color: 'light-success',
      title: '850',
      subtitle: 'Alumni',
      customClass: '',
    },
  ],
  statisticsOrder: {
    series: [
      {
        name: '2020',
        data: [45, 85, 65, 45, 65],
      },
    ],
  },
  statisticsProfit: {
    series: [
      {
        data: [0, 20, 5, 30, 15, 45],
      },
    ],
  },
  earningsChart: {
    series: [8, 2, 20],
  },
  revenue: {
    years: ['2018-2019', '2019-2020', '2020-2021', '2021-2022'],
    price: '690',
    budget: '690',
    revenueReport: {
      series: [
        {
          name: 'Earning',
          data: [570, 750, 900, 950],
        },
        {
          name: 'Expense',
          data: [-400, -590, -645, -780],
        },
      ],
    },
    budgetChart: {
      series: [
        {
          data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62],
        },
        {
          data: [20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27],
        },
      ],
    },
  },
  companyTable: [
    {
      avatarImg: require('@/assets/images/icons/toolbox.svg'),
      title: 'Aden Baihaqi',
      subtitle: 'Guru',
      avatarIcon: 'MonitorIcon',
      avatarColor: 'light-primary',
      avatarTitle: 'Dinas',
      viewTitle: 'Pelatihan Guru',
      viewsub: '19-20 Oct 2021',
      revenue: '891.2',
      sales: '68',
      loss: true,
    },
    {
      avatarImg: require('@/assets/images/icons/parachute.svg'),
      title: 'Arief Bijaksana',
      subtitle: 'Wakabid Kurikulum',
      avatarIcon: 'WatchIcon',
      avatarColor: 'light-success',
      avatarTitle: 'Sakit',
      viewTitle: 'Batuk Flu',
      viewsub: '17 Oct 2021',
      revenue: '668.51',
      sales: '97',
      loss: false,
    },
    {
      avatarImg: require('@/assets/images/icons/brush.svg'),
      title: 'Ujang Sarip H',
      subtitle: 'Siswa | Kelas XII IPA',
      avatarIcon: 'WatchIcon',
      avatarColor: 'light-warning',
      avatarTitle: 'Sakit',
      viewTitle: 'Demam',
      viewsub: '16 Oct 2021',
      revenue: '522.29',
      sales: '62',
      loss: false,
    },
    {
      avatarImg: require('@/assets/images/icons/star.svg'),
      title: 'Eko Aulian Saputra',
      subtitle: 'Siswa | Kelas XII IPS',
      avatarIcon: 'CoffeeIcon',
      avatarColor: 'light-primary',
      avatarTitle: 'Izin',
      viewTitle: 'Lomba Pramuka',
      viewsub: '14 Oct 2021',
      revenue: '291.01',
      sales: '88',
      loss: false,
    },
    {
      avatarImg: require('@/assets/images/icons/book.svg'),
      title: 'Jesika Rahma',
      subtitle: 'Siswa | Kelas XII IPA',
      avatarIcon: 'CoffeeIcon',
      avatarColor: 'light-success',
      avatarTitle: 'Izin',
      viewTitle: 'Kegiatan LDK',
      viewsub: '7 Oct 2021',
      revenue: '783.93',
      sales: '16',
      loss: true,
    },
    {
      avatarImg: require('@/assets/images/icons/rocket.svg'),
      title: 'Oemar Azzein',
      subtitle: 'Siswa | Kelas X A',
      avatarIcon: 'WatchIcon',
      avatarColor: 'light-warning',
      avatarTitle: 'Sakit',
      viewTitle: 'Operasi Kecil',
      viewsub: '2 Oct 2021',
      revenue: '780.05',
      sales: '78',
      loss: false,
    },
    {
      avatarImg: require('@/assets/images/icons/speaker.svg'),
      title: 'Ibrahim Mahmud',
      subtitle: 'Siswa | Kelas X B',
      avatarIcon: 'WatchIcon',
      avatarColor: 'light-warning',
      avatarTitle: 'Sakit',
      viewTitle: 'Gigi',
      viewsub: '2 Oct 2021',
      revenue: '531.49',
      sales: '42',
      loss: false,
    },
  ],
  meetup: {
    mediaData: [
      { avatar: 'CalendarIcon', title: 'Kamis, Dec 20, 2021', subtitle: '07:30 to 10:00 WIB' },
      { avatar: 'MapPinIcon', title: 'Hotel Horison', subtitle: 'Ciawi, Bogor' },
    ],
    avatars: [
      { avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'), fullName: 'Aden Baihaqi' },
      { avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'), fullName: 'Arief Bijaksana' },
      { avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'), fullName: 'Devy Ferdiansyah' },
      { avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'), fullName: 'Saiful Bahri' },
      { avatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'), fullName: 'Jesika Rahma' },
    ],
  },
  goalOverview: {
    completed: '54',
    inProgress: '27',
    series: [83],
  },
  transactionData: [
    {
      mode: 'Absensi Belajar',
      types: 'Presensi Siswa di Kelas',
      avatar: 'ClockIcon',
      avatarVariant: 'light-primary',
      payment: '15',
      deduction: true,
    },
    {
      mode: 'Agenda Mengajar',
      types: 'Laporan Mengajar',
      avatar: 'CheckIcon',
      avatarVariant: 'light-success',
      payment: '14',
      deduction: false,
    },
    {
      mode: 'Tugas',
      types: 'Pemberian Tugas Siswa',
      avatar: 'BookmarkIcon',
      avatarVariant: 'light-danger',
      payment: '9',
      deduction: false,
    },
    {
      mode: 'Penilaian',
      types: 'Input Nilai',
      avatar: 'AwardIcon',
      avatarVariant: 'light-warning',
      payment: '12',
      deduction: true,
    },
    {
      mode: 'Pengajuan RPP',
      types: 'RPP Guru',
      avatar: 'ClipboardIcon',
      avatarVariant: 'light-info',
      payment: '8',
      deduction: false,
    },
  ],
}
/* eslint-disable global-require */
mock.onGet('/ecommerce/data').reply(() => [200, data])
