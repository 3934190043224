/* eslint-disable import/extensions */
export default [
  {
    path: '/data-assetpemeliharaan',
    name: 'data-assetpemeliharaan',
    component: () => import('@/views/inventaris/assetpemeliharaan/list/AssetPemeliharaanList'),
    meta: {
      pageTitle: 'Asset',
      breadcrumb: [
        {
          text: 'Pemeliharaan',
        },
        {
          text: 'Data Pemeliharaan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-assetpemeliharaan/add',
    name: 'data-assetpemeliharaan-add',
    component: () => import('@/views/students/add/StudentCreate'),
    meta: {
      pageTitle: 'Keassetpemeliharaanan',
      breadcrumb: [
        {
          text: 'assetpemeliharaan',
        },
        {
          text: 'Tambah Data assetpemeliharaan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-assetpemeliharaan/edit/:id',
    name: 'data-assetpemeliharaan-edit',
    component: () => import('@/views/students/edit/StudentEdit'),
    meta: {
      pageTitle: 'Keassetpemeliharaanan',
      breadcrumb: [
        {
          text: 'assetpemeliharaan',
        },
        {
          text: 'Update Data assetpemeliharaan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-assetpemeliharaan/:id',
    name: 'data-assetpemeliharaan-detail',
    component: () => import('@/views/students/list/StudentDetail'),
    meta: {
      pageTitle: 'Keassetpemeliharaanan',
      breadcrumb: [
        {
          text: 'assetpemeliharaan',
        },
        {
          text: 'Detail Data assetpemeliharaan',
          active: true,
        },
      ],
    },
  },

]
