/* eslint-disable import/extensions */
export default [
  {
    path: '/data-arsipkeluar',
    name: 'data-arsipkeluar',
    component: () => import('@/views/surat/arsipkeluar/list/ArsipKeluarList'),
    meta: {
      pageTitle: 'Management Surat',
      breadcrumb: [
        {
          text: 'Arsip Keluar',
        },
        {
          text: 'Data Arsip Keluar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-arsipkeluar/add',
    name: 'data-arsipkeluar-add',
    component: () => import('@/views/surat/arsipkeluar/add/ArsipKeluarCreate'),
    meta: {
      pageTitle: 'Management Surat',
      breadcrumb: [
        {
          text: 'Arsip Keluar',
        },
        {
          text: 'Tambah Data Arsip Keluar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-arsipkeluar/edit/:id',
    name: 'data-arsipkeluar-edit',
    component: () => import('@/views/surat/arsipkeluar/edit/ArsipKeluarEdit'),
    meta: {
      pageTitle: 'Management Surat',
      breadcrumb: [
        {
          text: 'Arsip Keluar',
        },
        {
          text: 'Update Data Arsip Keluar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-arsipkeluar/:id',
    name: 'data-arsipkeluar-detail',
    component: () => import('@/views/surat/arsipkeluar/list/ArsipKeluarDetail'),
    meta: {
      pageTitle: 'Management Surat',
      breadcrumb: [
        {
          text: 'Arsip Keluar',
        },
        {
          text: 'Detail Data Arsip Keluar',
          active: true,
        },
      ],
    },
  },

]
