/* eslint-disable import/extensions */
export default [
  {
    path: '/kegiatan-sekolah-siswa',
    name: 'kegiatan-sekolah-siswa',
    component: () => import('@/views/kegiatansekolahsiswa/list/KegiatanSekolahList'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Kegiatan Sekolah Siswa',
        },
        {
          text: 'Data Kegiatan Sekolah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/kegiatan-sekolah-siswa/add',
    name: 'kegiatan-sekolah-siswa-add',
    component: () => import('@/views/kegiatansekolahsiswa/add/KegiatanSekolahCreate'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Kegiatan Sekolah Siswa',
        },
        {
          text: 'Tambah Kegiatan Sekolah Siswa',
          active: true,
        },
      ],
    },
  },
  {
    path: '/kegiatan-sekolah-siswa/edit/:id',
    name: 'kegiatan-sekolah-siswa-edit',
    component: () => import('@/views/kegiatansekolahsiswa/edit/KegiatanSekolahEdit'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Kegiatan Sekolah Siswa',
        },
        {
          text: 'Update Kegiatan Sekolah Siswa',
          active: true,
        },
      ],
    },
  },
  {
    path: '/kegiatan-sekolah-siswa/:id',
    name: 'kegiatan-sekolah-siswa-detail',
    component: () => import('@/views/kegiatansekolahsiswa/list/KegiatanSekolahDetail'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Kegiatan Sekolah Siswa',
        },
        {
          text: 'Detail Kegiatan Sekolah Siswa',
          active: true,
        },
      ],
    },
  },

]
