/* eslint-disable import/extensions */
export default [
  {
    path: '/data-set-umum-kategori',
    name: 'data-set-umum-kategori',
    component: () => import('@/views/students/list/StudentList'),
    meta: {
      pageTitle: 'Keset-umum-kategorian',
      breadcrumb: [
        {
          text: 'set-umum-kategori',
        },
        {
          text: 'Data set-umum-kategori',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-set-umum-kategori/add',
    name: 'data-set-umum-kategori-add',
    component: () => import('@/views/students/add/StudentCreate'),
    meta: {
      pageTitle: 'Keset-umum-kategorian',
      breadcrumb: [
        {
          text: 'set-umum-kategori',
        },
        {
          text: 'Tambah Data set-umum-kategori',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-set-umum-kategori/edit/:id',
    name: 'data-set-umum-kategori-edit',
    component: () => import('@/views/students/edit/StudentEdit'),
    meta: {
      pageTitle: 'Keset-umum-kategorian',
      breadcrumb: [
        {
          text: 'set-umum-kategori',
        },
        {
          text: 'Update Data set-umum-kategori',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-set-umum-kategori/:id',
    name: 'data-set-umum-kategori-detail',
    component: () => import('@/views/students/list/StudentDetail'),
    meta: {
      pageTitle: 'Keset-umum-kategorian',
      breadcrumb: [
        {
          text: 'set-umum-kategori',
        },
        {
          text: 'Detail Data set-umum-kategori',
          active: true,
        },
      ],
    },
  },

]
