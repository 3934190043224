/* eslint-disable import/extensions */
export default [
  {
    path: '/generate-spp',
    name: 'generate-spp',
    component: () => import('@/views/keuangan/generatespp/list/GenerateSPPList'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Generate SPP',
        },
        {
          text: 'Generate SPP',
          active: true,
        },
      ],
    },
  },
  {
    path: '/generate-spp/add',
    name: 'generate-spp-add',
    component: () => import('@/views/keuangan/generatespp/add/GenerateSPPCreate'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Generate SPP',
        },
        {
          text: 'Generate SPP',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master-keuangan/edit/:id',
    name: 'master-keuangan-edit',
    component: () => import('@/views/keuangan/generatespp/edit/GenerateSPPEdit'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Generate SPP',
        },
        {
          text: 'Generate SPP',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master-keuangan/:id',
    name: 'master-keuangan-detail',
    component: () => import('@/views/keuangan/generatespp/list/GenerateSPPDetail'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Generate SPP',
        },
        {
          text: 'Detail Master Keuangan',
          active: true,
        },
      ],
    },
  },

]
