/* eslint-disable import/extensions */
export default [
  {
    path: '/import-foto-pegawai',
    name: 'import-foto-pegawai',
    component: () => import('@/views/kepegawaian/importfoto/ImportFoto'),
    meta: {
      pageTitle: 'Kepegawaian',
      breadcrumb: [
        {
          text: 'Data Kepegawaian',
        },
        {
          text: 'Import Foto',
          active: true,
        },
      ],
    },
  },

]
