/* eslint-disable import/extensions */
export default [
  {
    path: '/transaksi-proses-gaji',
    name: 'transaksi-proses-gaji',
    component: () => import('@/views/penggajian/prosesgaji/list/ProsesGajiList'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Payroll',
        },
        {
          text: 'Payroll',
          active: true,
        },
      ],
    },
  },
  {
    path: '/transaksi-proses-gaji/add',
    name: 'transaksi-proses-gaji-add',
    component: () => import('@/views/penggajian/prosesgaji/add/ProsesGajiCreate'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Payroll',
        },
        {
          text: 'Payroll',
          active: true,
        },
      ],
    },
  },
  {
    path: '/transaksi-proses-gaji/edit/:id',
    name: 'transaksi-proses-gaji-edit',
    component: () => import('@/views/penggajian/prosesgaji/edit/ProsesGajiEdit'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'Payroll',
        },
        {
          text: 'Payroll',
          active: true,
        },
      ],
    },
  },
  {
    path: '/transaksi-proses-gaji/:id',
    name: 'transaksi-proses-gaji-detail',
    component: () => import('@/views/penggajian/prosesgaji/list/ProsesGajiDetail'),
    meta: {
      pageTitle: 'Penggajian',
      breadcrumb: [
        {
          text: 'prosesgaji',
        },
        {
          text: 'prosesgaji',
          active: true,
        },
      ],
    },
  },

]
