/* eslint-disable import/extensions */
export default [
  {
    path: '/data-generate',
    name: 'data-generate',
    component: () => import('@/views/surat/generatesurat/list/GenerateSuratList'),
    meta: {
      pageTitle: 'Management Surat',
      breadcrumb: [
        {
          text: 'Generate Surat',
        },
        {
          text: 'Data Generate Surat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-generate/add',
    name: 'data-generate-add',
    component: () => import('@/views/surat/generatesurat/add/GenerateSuratCreate'),
    meta: {
      pageTitle: 'Management Surat',
      breadcrumb: [
        {
          text: 'Generate Surat',
        },
        {
          text: 'Tambah Data Generate Surat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-generate/edit/:id',
    name: 'data-generate-edit',
    component: () => import('@/views/surat/generatesurat/edit/GenerateSuratEdit'),
    meta: {
      pageTitle: 'Management Surat',
      breadcrumb: [
        {
          text: 'Generate Surat',
        },
        {
          text: 'Update Data Generate Surat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-generate/:id',
    name: 'data-generate-detail',
    component: () => import('@/views/surat/generatesurat/list/GenerateSuratDetail'),
    meta: {
      pageTitle: 'Management Surat',
      breadcrumb: [
        {
          text: 'Generate Surat',
        },
        {
          text: 'Detail Data Generate Surat',
          active: true,
        },
      ],
    },
  },

]
