/* eslint-disable import/extensions */
export default [
  {
    path: '/role-menu-data',
    name: 'role-menu-data',
    component: () => import('@/views/role/datarolemenu/list/RoleList'),
    meta: {
      pageTitle: 'Role Management',
      breadcrumb: [
        {
          text: 'Role Management',
        },
        {
          text: 'Data Role',
          active: true,
        },
      ],
    },
  },
  {
    path: '/role-menu-data/add',
    name: 'role-menu-data-add',
    component: () => import('@/views/role/datarolemenu/add/RoleCreate'),
    meta: {
      pageTitle: 'Role Management',
      breadcrumb: [
        {
          text: 'Role Management',
        },
        {
          text: 'Tambah Data Role',
          active: true,
        },
      ],
    },
  },
  {
    path: '/role-menu-data/edit/:id',
    name: 'role-menu-data-edit',
    component: () => import('@/views/role/datarolemenu/edit/RoleEdit'),
    meta: {
      pageTitle: 'Role Management',
      breadcrumb: [
        {
          text: 'Role Management',
        },
        {
          text: 'Update Data Role',
          active: true,
        },
      ],
    },
  },
  {
    path: '/role-menu-data/:id',
    name: 'role-menu-data-detail',
    component: () => import('@/views/role/datarolemenu/list/RoleDetail'),
    meta: {
      pageTitle: 'Role Management',
      breadcrumb: [
        {
          text: 'Role Management',
        },
        {
          text: 'Detail Data Role',
          active: true,
        },
      ],
    },
  },
]
