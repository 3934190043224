/* eslint-disable import/extensions */
export default [
  {
    path: '/pembayaran-bimbel',
    name: 'pembayaran-bimbel',
    component: () => import('@/views/keuangan/pembayaranbimbel/list/PembayaranBimbelList'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Pembayaran Bimbel',
        },
        {
          text: 'Pembayaran Bimbel',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pembayaran-bimbel/add',
    name: 'pembayaran-bimbel-add',
    component: () => import('@/views/keuangan/pembayaranbimbel/add/PembayaranBimbelCreate'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Pembayaran Bimbel',
        },
        {
          text: 'Pembayaran Bimbel',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pembayaran-bimbel/edit/:id',
    name: 'pembayaran-bimbel-edit',
    component: () => import('@/views/keuangan/pembayaranbimbel/edit/PembayaranBimbelEdit'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Pembayaran Bimbel',
        },
        {
          text: 'Pembayaran Bimbel',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pembayaran-bimbel/:id',
    name: 'pembayaran-bimbel-detail',
    component: () => import('@/views/keuangan/pembayaranbimbel/list/PembayaranBimbelDetail'),
    meta: {
      pageTitle: 'Keuangan',
      breadcrumb: [
        {
          text: 'Pembayaran Bimbel',
        },
        {
          text: 'Pembayaran Bimbel',
          active: true,
        },
      ],
    },
  },

]
