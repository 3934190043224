/* eslint-disable import/extensions */
export default [
  {
    path: '/data-arsipmasuk',
    name: 'data-arsipmasuk',
    component: () => import('@/views/surat/arsipmasuk/list/ArsipMasukList'),
    meta: {
      pageTitle: 'Management Surat',
      breadcrumb: [
        {
          text: 'Arsip Masuk',
        },
        {
          text: 'Data Arsip Masuk',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-arsipmasuk/add',
    name: 'data-arsipmasuk-add',
    component: () => import('@/views/surat/arsipmasuk/add/ArsipMasukCreate'),
    meta: {
      pageTitle: 'Management Surat',
      breadcrumb: [
        {
          text: 'Arsip Masuk',
        },
        {
          text: 'Tambah Arsip Masuk',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-arsipmasuk/edit/:id',
    name: 'data-arsipmasuk-edit',
    component: () => import('@/views/surat/arsipmasuk/edit/ArsipMasukEdit'),
    meta: {
      pageTitle: 'Management Surat',
      breadcrumb: [
        {
          text: 'Arsip Masuk',
        },
        {
          text: 'Update Arsip Masuk',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-arsipmasuk/:id',
    name: 'data-arsipmasuk-detail',
    component: () => import('@/views/surat/arsipmasuk/list/ArsipMasukDetail'),
    meta: {
      pageTitle: 'Management Surat',
      breadcrumb: [
        {
          text: 'Arsip Masuk',
        },
        {
          text: 'Detail Arsip Masuk',
          active: true,
        },
      ],
    },
  },

]
