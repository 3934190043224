/* eslint-disable import/extensions */
export default [
  {
    path: '/data-voting',
    name: 'data-voting',
    component: () => import('@/views/osis/voting/list/VotingList'),
    meta: {
      pageTitle: 'Voting',
      breadcrumb: [
        {
          text: 'OSIS',
        },
        {
          text: 'Data Voting',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-voting/add',
    name: 'data-voting-add',
    component: () => import('@/views/osis/voting/add/VotingCreate'),
    meta: {
      pageTitle: 'Voting',
      breadcrumb: [
        {
          text: 'OSIS',
        },
        {
          text: 'Tambah Data Voting',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-voting/edit/:id',
    name: 'data-voting-edit',
    component: () => import('@/views/osis/voting/edit/VotingEdit'),
    meta: {
      pageTitle: 'Voting',
      breadcrumb: [
        {
          text: 'OSIS',
        },
        {
          text: 'Update Data Voting',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-voting/:id',
    name: 'data-voting-detail',
    component: () => import('@/views/osis/voting/list/VotingDetail'),
    meta: {
      pageTitle: 'Voting',
      breadcrumb: [
        {
          text: 'OSIS',
        },
        {
          text: 'Detail Data Voting',
          active: true,
        },
      ],
    },
  },

]
