/* eslint-disable import/extensions */
export default [
  {
    path: '/set-users',
    name: 'set-users',
    component: () => import('@/views/users/users/list/UsersList'),
    meta: {
      pageTitle: 'Users Management',
      breadcrumb: [
        {
          text: 'Users Management',
        },
        {
          text: 'Data Users Management',
          active: true,
        },
      ],
    },
  },
  {
    path: '/set-users/add',
    name: 'set-users-add',
    component: () => import('@/views/users/users/add/UsersCreate'),
    meta: {
      pageTitle: 'Users Management',
      breadcrumb: [
        {
          text: 'Users Management',
        },
        {
          text: 'Tambah Data Users',
          active: true,
        },
      ],
    },
  },
  {
    path: '/set-users/edit/:id',
    name: 'set-users-edit',
    component: () => import('@/views/users/users/edit/UsersEdit'),
    meta: {
      pageTitle: 'Users Management',
      breadcrumb: [
        {
          text: 'Users Management',
        },
        {
          text: 'Update Data Users',
          active: true,
        },
      ],
    },
  },
  {
    path: '/set-users/:id',
    name: 'set-users-detail',
    component: () => import('@/views/users/users/list/UsersDetail'),
    meta: {
      pageTitle: 'Users Management',
      breadcrumb: [
        {
          text: 'Data Users Management',
        },
        {
          text: 'Detail Data Users',
          active: true,
        },
      ],
    },
  },
  {
    path: '/profile-users',
    name: 'profile-users',
    component: () => import('@/views/profile/ProfilePage'),
    meta: {
      pageTitle: 'Users Management',
      breadcrumb: [
        {
          text: 'Users Management',
        },
        {
          text: 'Data Users Management',
          active: true,
        },
      ],
    },
  },
]
