/* eslint-disable import/extensions */
export default [
  {
    path: '/data-barangkeluar',
    name: 'data-barangkeluar',
    component: () => import('@/views/inventaris/inventarisbarangkeluar/list/InventarisBarangKeluarList'),
    meta: {
      pageTitle: 'Barang Keluar',
      breadcrumb: [
        {
          text: 'Barang Keluar',
        },
        {
          text: 'Data Barang Keluar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-barangkeluar/add',
    name: 'data-barangkeluar-add',
    component: () => import('@/views/students/add/StudentCreate'),
    meta: {
      pageTitle: 'Kebarangkeluaran',
      breadcrumb: [
        {
          text: 'barangkeluar',
        },
        {
          text: 'Tambah Data barangkeluar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-barangkeluar/edit/:id',
    name: 'data-barangkeluar-edit',
    component: () => import('@/views/students/edit/StudentEdit'),
    meta: {
      pageTitle: 'Kebarangkeluaran',
      breadcrumb: [
        {
          text: 'barangkeluar',
        },
        {
          text: 'Update Data barangkeluar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-barangkeluar/:id',
    name: 'data-barangkeluar-detail',
    component: () => import('@/views/students/list/StudentDetail'),
    meta: {
      pageTitle: 'Kebarangkeluaran',
      breadcrumb: [
        {
          text: 'barangkeluar',
        },
        {
          text: 'Detail Data barangkeluar',
          active: true,
        },
      ],
    },
  },

]
