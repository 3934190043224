/* eslint-disable import/extensions */
export default [
  {
    path: '/data-asset',
    name: 'data-asset',
    component: () => import('@/views/inventaris/dataasset/list/DataAssetList'),
    meta: {
      pageTitle: 'Asset',
      breadcrumb: [
        {
          text: 'Asset',
        },
        {
          text: 'Data Asset',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-asset/add',
    name: 'data-asset-add',
    component: () => import('@/views/students/add/StudentCreate'),
    meta: {
      pageTitle: 'Keassetan',
      breadcrumb: [
        {
          text: 'asset',
        },
        {
          text: 'Tambah Data asset',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-asset/edit/:id',
    name: 'data-asset-edit',
    component: () => import('@/views/students/edit/StudentEdit'),
    meta: {
      pageTitle: 'Keassetan',
      breadcrumb: [
        {
          text: 'asset',
        },
        {
          text: 'Update Data asset',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-asset/:id',
    name: 'data-asset-detail',
    component: () => import('@/views/students/list/StudentDetail'),
    meta: {
      pageTitle: 'Keassetan',
      breadcrumb: [
        {
          text: 'asset',
        },
        {
          text: 'Detail Data asset',
          active: true,
        },
      ],
    },
  },

]
