/* eslint-disable import/extensions */
export default [
  {
    path: '/mata-pelajaran',
    name: 'mata-pelajaran',
    component: () => import('@/views/akademik/matapelajaran/matapelajaran/list/MataPelajaranList'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Mata Pelajaran',
        },
        {
          text: 'Mata Pelajaran',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mata-pelajaran/add',
    name: 'mata-pelajaran-add',
    component: () => import('@/views/akademik/matapelajaran/matapelajaran/add/MataPelajaranCreate'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Mata Pelajaran',
        },
        {
          text: 'Tambah Mata Pelajaran',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mata-pelajaran/edit/:id',
    name: 'mata-pelajaran-edit',
    component: () => import('@/views/akademik/matapelajaran/matapelajaran/edit/MataPelajaranEdit'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Mata Pelajaran',
        },
        {
          text: 'Update Mata Pelajaran',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mata-pelajaran/:id',
    name: 'mata-pelajaran-detail',
    component: () => import('@/views/akademik/matapelajaran/matapelajaran/list/MataPelajaranDetail'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Mata Pelajaran',
        },
        {
          text: 'Detail Mata Pelajaran',
          active: true,
        },
      ],
    },
  },
]
