/* eslint-disable import/extensions */
export default [
  {
    path: '/agenda-mengajar',
    name: 'agenda-mengajar',
    component: () => import('@/views/akademik/agenda/agendamengajar/list/AgendaMengajarList'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Data Kenaikan Kelas',
        },
        {
          text: 'Data Kenaikan Kelas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/jadwal-mata-pelajaran/add',
    name: 'jadwal-mata-pelajaran-add',
    component: () => import('@/views/jadwal/jadwalmatapelajaran/add/JadwalMataPelajaranCreate'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Data Kenaikan Kelas',
        },
        {
          text: 'Tambah Data Kenaikan Kelas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/jadwal-mata-pelajaran/edit/:id',
    name: 'jadwal-mata-pelajaran-edit',
    component: () => import('@/views/jadwal/jadwalmatapelajaran/edit/JadwalMataPelajaranEdit'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Data Kenaikan Kelas',
        },
        {
          text: 'Update Data Kenaikan Kelas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/jadwal-mata-pelajaran/:id',
    name: 'jadwal-mata-pelajaran-detail',
    component: () => import('@/views/jadwal/jadwalmatapelajaran/list/JadwalMataPelajaranDetail'),
    meta: {
      pageTitle: 'Akademik',
      breadcrumb: [
        {
          text: 'Data Kenaikan Kelas',
        },
        {
          text: 'Detail Data Kenaikan Kelas',
          active: true,
        },
      ],
    },
  },
]
