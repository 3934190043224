/* eslint-disable import/extensions */
export default [
  {
    path: '/data-prestasi',
    name: 'data-prestasi',
    component: () => import('@/views/prestasi/list/PrestasiList'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Buku Induk Siswa',
        },
        {
          text: 'Data Prestasi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-prestasi/add',
    name: 'data-prestasi-add',
    component: () => import('@/views/prestasi/add/PrestasiCreate'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Buku Induk Siswa',
        },
        {
          text: 'Tambah Data Prestasi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-prestasi/edit/:id',
    name: 'data-prestasi-edit',
    component: () => import('@/views/prestasi/edit/PrestasiEdit'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Buku Induk Siswa',
        },
        {
          text: 'Update Data Prestasi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-prestasi/:id',
    name: 'data-prestasi-detail',
    component: () => import('@/views/prestasi/list/PrestasiDetail'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Buku Induk Siswa',
        },
        {
          text: 'Detail Data Prestasi',
          active: true,
        },
      ],
    },
  },

]
