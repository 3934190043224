/* eslint-disable import/extensions */
export default [
  {
    path: '/rekap-izin-siswa',
    name: 'rekap-izin-siswa',
    component: () => import('@/views/absensi/izin/rekapizin/list/RekapIzinList'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Rekap Izin Siswa',
        },
        {
          text: 'Rekap Izin Siswa',
          active: true,
        },
      ],
    },
  },
  {
    path: '/generate-jadwal/add',
    name: 'generate-jadwal-add',
    component: () => import('@/views/absensi/izin/rekapizin/add/RekapIzinCreate'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Data Kenaikan Kelas',
        },
        {
          text: 'Tambah Data Kenaikan Kelas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/generate-jadwal/edit/:id',
    name: 'generate-jadwal-edit',
    component: () => import('@/views/absensi/izin/rekapizin/edit/RekapIzinEdit'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Data Kenaikan Kelas',
        },
        {
          text: 'Update Data Kenaikan Kelas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/generate-jadwal/:id',
    name: 'generate-jadwal-detail',
    component: () => import('@/views/absensi/izin/rekapizin/list/RekapIzinDetail'),
    meta: {
      pageTitle: 'Absensi',
      breadcrumb: [
        {
          text: 'Data Kenaikan Kelas',
        },
        {
          text: 'Detail Data Kenaikan Kelas',
          active: true,
        },
      ],
    },
  },
]
