/* eslint-disable import/extensions */
export default [
  {
    path: '/data-kunjungan-rumah',
    name: 'data-kunjungan-rumah',
    component: () => import('@/views/konseling/kunjunganrumah/list/KunjunganRumahList'),
    meta: {
      pageTitle: 'Kunjungan Rumah',
      breadcrumb: [
        {
          text: 'Konseling',
        },
        {
          text: 'Data Kunjungan Rumah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kunjungan-rumah/add',
    name: 'data-kunjungan-rumah-add',
    component: () => import('@/views/konseling/kunjunganrumah/add/KunjunganRumahCreate'),
    meta: {
      pageTitle: 'Kunjungan Rumah',
      breadcrumb: [
        {
          text: 'Konseling',
        },
        {
          text: 'Tambah Data Kunjungan Rumah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kunjungan-rumah/edit/:id',
    name: 'data-kunjungan-rumah-edit',
    component: () => import('@/views/konseling/kunjunganrumah/edit/KunjunganRumahEdit'),
    meta: {
      pageTitle: 'Kunjungan Rumah',
      breadcrumb: [
        {
          text: 'Konseling',
        },
        {
          text: 'Update Data Kunjungan Rumah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-kunjungan-rumah/:id',
    name: 'data-kunjungan-rumah-detail',
    component: () => import('@/views/konseling/kunjunganrumah/list/KunjunganRumahDetail'),
    meta: {
      pageTitle: 'Kesiswaan',
      breadcrumb: [
        {
          text: 'Konseling',
        },
        {
          text: 'Detail Data Kunjungan Rumah',
          active: true,
        },
      ],
    },
  },

]
